import { toggleLoader } from '@actions/LoaderActions';
import { logout } from '@actions/user/user';
import { chageUsernameIdentity, chageUsernameOldSite } from '@api/user/user';
import ModalConfirm from '@components/common/ModalConfirm/ModalConfirm';
import { IDENTITY_ROLE_OPERATOR_ECONOMIC, IDENTITY_ROLE_PARTENER_VALORI_MASURATE } from '@constants/Roles/RolesIdentity';
import { IState } from '@type/store';
import { jwtDecode } from 'jwt-decode';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const ChangeEmailAccesEmail = (props: any) => {
  const [error, setError] = useState<string>('');
  const [values, setValues] = useState<any>(null);
  const [showModalEmailWasChanged, setShowModalEmailWasChanged] = useState<boolean>(false);
  const [showModalNotAuthorized, setShowModalNotAuthorized] = useState<boolean>(false);
  const [currentUsername, setCurrentUsername] = useState<string>('');
  const currentAuthToken: string | undefined = useSelector<IState>((state) => state.auth.token) as string;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(toggleLoader(true));

    if (!currentAuthToken) {
      setShowModalNotAuthorized(true);
      dispatch(toggleLoader(false));
    } else {
      const url = new URL(window.location.href);
      const params = url.search;
      const token = params.substring(7, params.length);
      changeUsername(token);
    }
  }, []);

  const changeUsername = async (token: string) => {
    const payloadChageUsernameIdentityVerify = {
      value: token,
      changeUsername: false
    };

    // se aduc datele necesare actualizarii pe vechi (newUsername, oldUsername)
    await chageUsernameIdentity(payloadChageUsernameIdentityVerify)
      .then(async (res: any) => {
        if (res.success) {
          //validate user
          let jwt: any = jwtDecode(currentAuthToken);

          const roles = jwt.groups || [];

          if (jwt?.sub !== res.oldUsername) {
            setCurrentUsername(res.oldUsername);
            setShowModalNotAuthorized(true);
            return;
          }

          //payload actualizare email pe vechi
          const payloadChageUsernameOldsite = {
            email: res.newUsername,
            currentEmail: res.oldUsername,
            token: currentAuthToken,
            isRollback: 0
          };
          const payloadRollbackUsernameOldsite = {
            email: res.oldUsername,
            currentEmail: res.newUsername,
            token: currentAuthToken,
            isRollback: 1
          };
          setValues(payloadChageUsernameOldsite);

          //schimbare doar pe site nou pentru rol PARTENER_VALORI_MASURATE si OPERATOR_ECONOMIC
          if (roles.includes(IDENTITY_ROLE_PARTENER_VALORI_MASURATE) || roles.includes(IDENTITY_ROLE_OPERATOR_ECONOMIC)) {
            //modificare username pe nou
                const payloadChageUsernameIdentity = { value: token, changeUsername: true };
                await chageUsernameIdentity(payloadChageUsernameIdentity)
                  .then(async (res: any) => {
                    if (res.success) {
                      setShowModalEmailWasChanged(true);
                    } else {
                      setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                    }
                  })
                  .catch(function (error: any) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                  });
          } else {
            await chageUsernameOldSite(payloadChageUsernameOldsite)
            .then(async (res: any) => {
              if (res.data && res.data.email && res.data.email === payloadChageUsernameOldsite.email) {
                //succes la modificare username pe vechi
                //modificare username pe nou
                const payloadChageUsernameIdentity = { value: token, changeUsername: true };
                await chageUsernameIdentity(payloadChageUsernameIdentity)
                  .then(async (res: any) => {
                    if (res.success) {
                      setShowModalEmailWasChanged(true);
                    } else {
                      //rollback pe vechi
                      chageUsernameOldSite(payloadRollbackUsernameOldsite);
                      setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                    }
                  })
                  .catch(function (error: any) {
                    //rollback pe vechi
                    chageUsernameOldSite(payloadRollbackUsernameOldsite);
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                  });
              } else {
                //eroare la modificare username pe vechi
                setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
              }
            })
            .catch(async function (error: any) {
              //eroare la modificare username pe vechi
              setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
            });
          }
        } else {
          if (res.errors && res.errors[0] && res.errors[0].errorDescription === 'TokenExpired') {
            setError('Link-ul de confirmare schimbare email a expirat.');
          } else {
            setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
          }
        }
      })
      .catch(function (error: any) {
        setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
      });
    dispatch(toggleLoader(false));
  };

  const handleCloseEmailWasChangedModal = () => {
    setShowModalEmailWasChanged(false);
    dispatch(logout());
    document.cookie = 'token=';
    window.location.href = '/login';
  };
  const handleCloseNotAuthorizedModal = () => {
    dispatch(logout());
    document.cookie = 'token=';
    window.location.href = '/login';
  };

  return (
    <Fragment>
      {error && <div style={{ color: 'red', fontWeight: 'bold' }}>{error}</div>}
      <ModalConfirm modalTitle="Succes!" showModal={showModalEmailWasChanged} onClose={handleCloseEmailWasChangedModal}>
        <p>Adresa de e-mail a fost actualizată cu succes, de acum vă puteți accesa contul folosind adresa {values && values.email}</p>
      </ModalConfirm>

      <ModalConfirm modalTitle="Eroare!" showModal={showModalNotAuthorized} onClose={handleCloseNotAuthorizedModal}>
        {currentUsername ? (
          <p>
            Pentru a actualiza adresa de e-mail este nevoie sa fiți autentificat în contul {currentUsername}, vă rugăm să reaccesați acest
            link după ce vă autentificați.
          </p>
        ) : (
          <p>
            Pentru a actualiza adresa de e-mail este nevoie sa fiți autentificat, vă rugăm să reaccesați acest link după ce vă
            autentificați.
          </p>
        )}
      </ModalConfirm>
    </Fragment>
  );
};

export default ChangeEmailAccesEmail;
