import React, { useEffect, useState } from 'react';
import Configuration from '@kentico-json/global/backOffice/posf.json';
import * as notification from '@lib/Notification';
import { IState } from '@type/store';
import { useDispatch, useSelector } from 'react-redux';
import { setPath } from '@actions/BoActions';
import { GetConfig, ActionSave, GetResults } from '@api/backOffice/fetchResults';
import { toggleLoader } from '@actions/LoaderActions';
import { BackOfficeSearch, Filter, ValueToSet } from '@components/delgaz/BackOffice/PageWrapper';
import { DataTypes, DetailActionResponse } from '@type/backOfficeModule';
import moment from 'moment';

export type BackOffice = typeof Configuration;

export type MetaData = {
  pageIndex: number;
  pageSize: number;
  totalItemsCount: number;
};

export type ResponseType = {
  items: Array<any>;
  metadata?: MetaData;
};

export type DetailedElement = {
  [key: string]: string | any;
};

export type BackOfficeContextType = {
  config?: BackOffice | null;
  onRowAction?: (values: InputValues, actionId: string) => Promise<boolean>;
  fetchDetails?: (values: InputValues, actionId: string) => any;
  response?: ResponseType | null;
  fetchResults: (searchModel: BackOfficeSearch, includeFilters: boolean) => void;
  isFullWidth: boolean;
  detailedElement?: DetailedElement | null;
  updateDetailedElement: (item: DetailedElement) => void;
  setSearchModel: (searchModel: BackOfficeSearch) => void;
  searchModel: BackOfficeSearch;
  reloadListResults: () => void;
  onFilterValueChange: ({ name, value, itemTypeId }: ValueToSet) => void;
  requiredFilterNotFound: boolean;
};

export type InputValues = {
  [key: string]: string | boolean | number | string[];
};

export const BackOfficeContext = React.createContext<BackOfficeContextType | null>({} as BackOfficeContextType);

type Props = {
  children: JSX.Element;
  baseUrl: string;
  securityContext: string;
};

export type ColumnType = {
  displayName: string;
  name: string;
  dataTypeId: number;
  showInTable: boolean;
  columnSize: string | any;
};

const BackOfficeProvider = ({ children, baseUrl, securityContext }: Props) => {
  const [config, setConfig] = useState<BackOffice | null>(null);
  const [isFullWidth, setIsFullWidth] = useState<boolean>(true);
  const [response, setResponse] = useState<ResponseType | null>(null);
  const [detailedElement, setDetailedElement] = useState<DetailedElement | null>(null);
  const [searchModel, setSearchModel] = useState<BackOfficeSearch>(new BackOfficeSearch());
  const [requiredFilters, setRequiredFilters] = useState<string[]>([]);
  const [requiredFilterNotFound, setRequiredFilterNotFound] = useState<boolean>(false);
  const basePath: string = useSelector<IState>((state) => state.backOffice.basePath) as string;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setDetailedElement(null);
    };
  }, []);

  useEffect(() => {
    if (baseUrl) {
      dispatch(setPath(baseUrl, securityContext));
    }
  }, [baseUrl]);

  useEffect(() => {
    if (config?.filters) {
      let rF: string[] = [];
      config.filters.map((f) => {
        //@ts-ignore
        if (f.required) {
          rF.push(f.name);
        }
        if (f.dataTypeId == DataTypes.DateRange) {
          f.items?.forEach((fDate) => {
            //@ts-ignore
            if (fDate?.required) {
              //@ts-ignore
              rF.push(fDate.name);
            }
          });
        }
      });
      setRequiredFilters(rF);
    }
  }, [config?.filters]);

  useEffect(() => {
    if (searchModel.Filters && searchModel.Filters.length) {
      verifyRequredFilters();
    }
  }, [searchModel.Filters]);

  const getConfig = async () => {
    await GetConfig()
      .then((response: BackOffice) => {
        setConfig(response);
        if (response.displayModeId === 3) {
          //DetailedDynamic
          setIsFullWidth(false);
        } else {
          setIsFullWidth(
            response.displayModeId === 1 || response.columns.filter((column: { showInTable: boolean }) => !column.showInTable).length === 0
          );
        }
      })
      .catch((error) => {
        notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
      });
  };

  const fetchResults = async (searchModel: BackOfficeSearch, includeFilters: boolean = true) => {
    if (!verifyRequredFilters()) {
      setResponse(null);
      return;
    }

    //trim white spaces on String type filters value
    searchModel.Filters.forEach((filter: Filter) => {
      if (filter.DataTypeId === DataTypes.String) {
        let value = filter.Value as string;
        if (value.charAt(0) === ' ' || value.charAt(value.length - 1) === ' ') {
          value = value.trim();
          filter.Value = value;
        }
      }
    });

    //exclude "[]"
    searchModel.Filters = searchModel.Filters.filter((filter: Filter) => {
      if (
        (filter.DataTypeId == DataTypes.MultiSelectDropdownInt || filter.DataTypeId == DataTypes.MultiSelectDropdownString) &&
        filter.Value == '[]'
      ) {
        return false;
      }
      return true;
    });

    setDetailedElement(null);
    dispatch(toggleLoader(true));
    setResponse({
      items: [],
      metadata: {} as MetaData
    } as ResponseType);

    let model = includeFilters ? searchModel : Object.assign({}, { ...searchModel, Filters: [] });
    await GetResults({
      ...model,
      Filters: searchModel.Filters.map((filter: Filter) => {
        let stringValue;
        if (typeof filter.Value !== 'string') {
          stringValue = filter.Value + '';
        } else {
          stringValue = filter.Value;
        }
        return { ...filter, Value: stringValue };
      })
    })
      .then((response) => {
        setResponse(response);
      })
      .catch((error) => {
        if (error && error.status && error.status == 401) {
          notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
          setTimeout(() => {
            window.location.reload();
          }, 5000);
        } else {
          notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
        }
      });
    dispatch(toggleLoader(false));
  };

  useEffect(() => {
    getConfig();
  }, [basePath]);

  const onRowAction = async (values: InputValues, actionId: string): Promise<boolean> => {
    let isTechnicalError = false;
    let isSessionExpired = false;
    let response = (await ActionSave(values, actionId).catch((error) => {
      isTechnicalError = true;
      if (error.status == 401) {
        isSessionExpired = true;
        isTechnicalError = false;
      }
      return false;
    })) as { success: boolean; Success: boolean; errorMessage: string; ErrorMessage: string; [key: string]: string | boolean };
    if (response?.success === true || response?.Success === true) {
      notification.success(response.errorMessage ?? response.ErrorMessage ?? 'Acțiunea a fost efectuată cu succes!');
      fetchResults({
        ...searchModel,
        PageSize: config?.pagination.defaultPageSizeValue as number,
        SortColumn: config?.sortColumn
      });

      return true;
    } else {
      if (isTechnicalError) {
        notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
      } else if (isSessionExpired) {
        notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        notification.error(response.errorMessage ?? response.ErrorMessage ?? 'Verificati datele introduse!');
      }
      return false;
    }
  };

  const updateDetailedElement = (item: DetailedElement) => {
    setDetailedElement(item);
  };

  const fetchDetails = async (values: InputValues, actionId: string) => {
    let response: DetailActionResponse = await ActionSave(values, actionId);

    return response;
  };

  const reloadListResults = () => {
    fetchResults({
      ...searchModel,
      PageSize: config?.pagination.defaultPageSizeValue as number,
      SortColumn: config?.sortColumn
    });
  };

  const onFilterValueChange = async ({ name, value, itemTypeId }: ValueToSet) => {
    setSearchModel((prev) => {
      const filters = [...prev.Filters];
      const index = filters.findIndex((filter: Filter) => filter.Name === name);

      if (index === -1) {
        return {
          ...prev,
          Filters: [
            ...filters,
            {
              Name: name,
              Value: value,
              DataTypeId: itemTypeId
            }
          ]
        };
      } else {
        const updatedFilters = value
          ? [...filters.slice(0, index), { ...filters[index], Value: value }, ...filters.slice(index + 1)]
          : [...filters.slice(0, index), ...filters.slice(index + 1)];

        // Handle the DateRangeFrom and DateRangeTo logic
        if (itemTypeId === DataTypes.DateRangeFrom && value) {
          const nameTo = name.replace('From', 'To');
          const indexTo = filters.findIndex((x: Filter) => x.Name === nameTo && x.DataTypeId === DataTypes.DateRangeTo);

          if (indexTo !== -1 && moment(filters[indexTo].Value).isBefore(moment(value))) {
            return {
              ...prev,
              Filters: updatedFilters.filter((filter, index) => index !== indexTo)
            };
          }
        }

        return {
          ...prev,
          Filters: updatedFilters
        };
      }
    });
  };

  const verifyRequredFilters = () => {
    if (requiredFilters.length) {
      let requiredFilterNotFound = false;
      requiredFilters.forEach((requestedFilterName) => {
        let filterValueExists = searchModel.Filters.find((f) => f.Name == requestedFilterName);
        if (!filterValueExists) {
          requiredFilterNotFound = true;
        }
      });

      if (requiredFilterNotFound) {
        setRequiredFilterNotFound(true);
        return false;
      } else {
        setRequiredFilterNotFound(false);
        return true;
      }
    }
    return true;
  };

  return (
    <BackOfficeContext.Provider
      value={{
        config,
        onRowAction,
        fetchResults,
        response,
        fetchDetails,
        isFullWidth,
        detailedElement,
        updateDetailedElement,
        setSearchModel,
        searchModel,
        reloadListResults,
        onFilterValueChange,
        requiredFilterNotFound
      }}
    >
      {children}
    </BackOfficeContext.Provider>
  );
};

export default BackOfficeProvider;
