import React, { useRef, useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Select } from '@components/common/Select';
import ImputernicitPF from '@components/iConnect/forms/ImputernicitPF';
import ImputernicitPJ from '../forms/ImputernicitPJ';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { postStepTwo } from '@api/iConnect/iConnectGaz';
import Adress from '../forms/Adress';
import { IState } from '@type/store';
import {jwtDecode} from 'jwt-decode';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

const ReprezentantLegal = (props: { isEditing: any, requestId: any, dateId: any, adressType: any, handleAdressType: any, adressData: any, handleAdressData: any, startingModalOption: any, tipImputernicit: any, handleTipImputernicit: any, setStep: any, progress: any, currentStep: any, setProgress: any, formData: any, handleFormData: any }) => {
    const [clientTypeError, setClientTypeError] = useState('');
    const [adressTypeError, setAdressTypeError] = useState('');
    const [error, setError] = useState('');

    const { isEditing, requestId, dateId, adressType, handleAdressType, adressData, handleAdressData, startingModalOption, tipImputernicit, handleTipImputernicit, setStep, progress, currentStep, setProgress, formData, handleFormData } = props;
    const formRefPF = useRef(null);
    const formRefPJ = useRef(null);
    const adressRef = useRef(null);

    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    let jwt: JWT = jwtDecode(token);

    useEffect(() => {
        window.scrollTo(0, 0);

        startingModalOption === 2 && jwt.groups.includes('ClientFizic') && handleTipImputernicit('PF');
        startingModalOption === 2 && jwt.groups.includes('ClientJuridic') && handleTipImputernicit('PJ');
    }, []);

    const dispatch = useDispatch();

    const constructPayload = () => {
        const adresa = {
            Judet: formData.Judet,
            Localitate: formData.Localitate,
            Comuna: formData.Comuna,
            Strada: formData.Strada,
            Numar: formData.Numar,
            Bloc: formData.Bloc,
            Etaj: formData.Etaj,
            Scara: formData.Scara,
            Ap: formData.Ap
        };

        const adresaUser = {
            Judet: dateId.Judet,
            Localitate: dateId.Localitate,
            Comuna: dateId.Comuna,
            Strada: dateId.Strada,
            Numar: dateId.Numar,
            Bloc: dateId.Bloc,
            Etaj: dateId.Etaj,
            Scara: dateId.Scara,
            Ap: dateId.Ap
        };

        let corespAdr = null;
        if (adressType === 'AI') {
            corespAdr = adresa;
        } else if (adressType === 'AU') {
            corespAdr = adresaUser;
        } else if (adressType === 'AA') {
            corespAdr = adressData;
        }

        if (tipImputernicit === 'PJ') {
            formData.CIData = null;
        }

        const payload = {
            requestId: requestId,
            TipImputernicit: tipImputernicit,
            Imputernicit: {
                ...formData,
                Adresa: adresa
            },
            AdresaCorespondenta: corespAdr,
            TipCorespondenta: adressType
        };

        ['Judet', 'Localitate', 'Comuna', 'Strada', 'Numar', 'Bloc', 'Etaj', 'Scara', 'Ap'].forEach(e => delete payload.Imputernicit[e]);

        return payload;
    };

    const stepTwoApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepTwo(payload)
            .then((response: any) => {
                if (response && response.status === 200) {
                    setStep(currentStep + 1);
                    currentStep === progress && setProgress(currentStep + 1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setStep(currentStep + 1);
                    currentStep === progress && setProgress(currentStep + 1);
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            setClientTypeError('');
            setAdressTypeError('');
            setError('');
            let ac = document.getElementById('ac');
            ac?.classList.remove('red');

            if (tipImputernicit === '') {
                setClientTypeError('Te rugăm să alegi tipul de împuternicit.');
            }

            //@ts-ignore
            formRefPF.current?.submitForm();
            //@ts-ignore
            formRefPJ.current?.submitForm();

            if (tipImputernicit !== '') {
                if (adressType === '') {
                    setAdressTypeError('Te rugăm să alegi adresa de corespondență.');
                    let err = document.getElementById('ac');
                    err?.classList.add('red');
                } else {
                    if (adressType !== 'AI') {
                        //@ts-ignore
                        adressRef.current?.submitForm();
                    }
                }
            }

            //@ts-ignore
            if (formRefPF.current && formRefPF.current?.isValid === true) {
                if (adressType === 'AI') {
                    stepTwoApiCall(constructPayload());
                } else {
                    //@ts-ignore
                    if (adressType !== 'AI' && adressRef.current && adressRef.current?.isValid === true) {
                        stepTwoApiCall(constructPayload());
                    } else if (!adressRef.current && adressType !== '') {
                        // call endpoint
                        stepTwoApiCall(constructPayload());
                    }
                }

                //@ts-ignore
            } else if (formRefPJ.current && formRefPJ.current?.isValid === true) {
                if (adressType === 'AI') {
                    stepTwoApiCall(constructPayload());
                } else {
                    //@ts-ignore
                    if (adressType !== 'AI' && adressRef.current && adressRef.current?.isValid === true) {
                        stepTwoApiCall(constructPayload());
                    } else if (!adressRef.current && adressType !== '') {
                        // call endpoint
                        stepTwoApiCall(constructPayload());
                    }
                }
            }

        } else {
            setStep(currentStep + 1);
            currentStep === progress && setProgress(currentStep + 1);
        }
    };

    //eslint-disable-next-line
    const handleNext = () => {
        if (isEditing !== false) {
            setClientTypeError('');
            setAdressTypeError('');
            setError('');
            let ac = document.getElementById('ac');
            ac?.classList.remove('red');

            if (tipImputernicit !== '' && adressType === '') {
                setAdressTypeError('Te rugăm să alegi adresa de corespondență.');
                let err = document.getElementById('ac');
                err?.classList.add('red');
            }

            if (tipImputernicit === '') {
                setClientTypeError('Te rugăm să alegi tipul de împuternicit.');
            } else if (tipImputernicit !== '' && adressType !== '') {
                //@ts-ignore
                formRefPF.current?.submitForm();
                //@ts-ignore
                formRefPJ.current?.submitForm();

                //@ts-ignore
                if (formRefPF.current && formRefPF.current?.isValid === true) {
                    //@ts-ignore
                    adressRef.current?.submitForm();
                    //@ts-ignore
                    if (adressRef.current && adressRef.current?.isValid === true) {

                        // call endpoint
                        stepTwoApiCall(constructPayload());
                    } else if (!adressRef.current) {
                        // call endpoint
                        stepTwoApiCall(constructPayload());
                    }

                    // setStep(currentStep+1);
                    // currentStep === progress && setProgress(currentStep+1);
                    //@ts-ignore
                } else if (formRefPJ.current && formRefPJ.current?.isValid === true) {
                    //@ts-ignore
                    adressRef.current?.submitForm();

                    //@ts-ignore
                    if (adressRef.current && adressRef.current?.isValid === true) {

                        // call endpoint
                        stepTwoApiCall(constructPayload());
                    } else if (!adressRef.current) {
                        // call endpoint
                        stepTwoApiCall(constructPayload());
                    }
                    // setStep(currentStep+1);
                    // currentStep === progress && setProgress(currentStep+1);
                }
            }
        } else {
            setStep(currentStep + 1);
            currentStep === progress && setProgress(currentStep + 1);
        }
    };

    const handlePrevStep = () => {
        setStep(currentStep - 1);
    };

    return (
        <div className="prosumatori-reprezentant-legal m-3 w-100 d-flex align-self-center flex-column">
            <Row>
                <h4 className="register-section-title mt-3">Informații despre împuternicit</h4>
                <Col xs={12} sm={6}>
                    <Select
                        onChange={(e: any) => {
                            setClientTypeError('');
                            if (e.target.value === 'PF' && jwt.groups.includes('ClientJuridic')) handleFormData('', 'Email');
                            if (e.target.value === 'PJ' && jwt.groups.includes('ClientFizic')) handleFormData('', 'Email');
                            if (e.target.value === 'PF' && jwt.groups.includes('ClientFizic')) handleFormData(jwt.sub, 'Email');
                            if (e.target.value === 'PJ' && jwt.groups.includes('ClientJuridic')) handleFormData(jwt.sub, 'Email');
                            handleTipImputernicit(e.target.value);
                        }}
                        options={[{ name: 'Persoană fizică', id: 'PF' }, { name: 'Persoană juridică', id: 'PJ' }]}
                        placeholder={'Alege tipul de împuternicit'}
                        value={tipImputernicit}
                        mbZero
                        disabled={isEditing === false || startingModalOption === 2}
                    />
                    {clientTypeError !== '' && <p style={{ color: 'red', fontWeight: 'bold' }}>{clientTypeError}</p>}
                </Col>
            </Row>

            {(tipImputernicit === 'PF' && jwt.groups.includes('ClientFizic')) &&
                <Row className="mt-4">
                    <ImputernicitPF
                        isEditing={isEditing}
                        innerRef={formRefPF}
                        submit={handleNextStep}
                        formData={formData}
                        handleFormData={handleFormData}
                        isEmailDisabled={true}
                    />
                </Row>
            }

            {(tipImputernicit === 'PF' && !jwt.groups.includes('ClientFizic')) &&
                <Row className="mt-4">
                    <ImputernicitPF
                        isEditing={isEditing}
                        innerRef={formRefPF}
                        submit={handleNextStep}
                        formData={formData}
                        handleFormData={handleFormData}
                        isEmailDisabled={false}
                    />
                </Row>
            }

            {(tipImputernicit === 'PJ' && jwt.groups.includes('ClientFizic')) &&
                <Row className="mt-4">
                    <ImputernicitPJ
                        isEditing={isEditing}
                        innerRef={formRefPJ}
                        submit={handleNextStep}
                        formData={formData}
                        handleFormData={handleFormData}
                        isEmailDisabled={false}
                    />
                </Row>
            }

            {(tipImputernicit === 'PJ' && !jwt.groups.includes('ClientFizic')) &&
                <Row className="mt-4">
                    <ImputernicitPJ
                        isEditing={isEditing}
                        innerRef={formRefPJ}
                        submit={handleNextStep}
                        formData={formData}
                        handleFormData={handleFormData}
                        isEmailDisabled={true}
                    />
                </Row>
            }

            {(tipImputernicit !== '' && startingModalOption === 2) &&
                <Row>
                    <ChoiceComponent
                        title={'Adresa de corespondență este:'}
                        options={[{ title: 'Adresa împuternicitului', id: 'AI' }, { title: 'Altă adresă', id: 'AA' }]}
                        onChange={(option: number) => {
                            setAdressTypeError('');
                            handleAdressType(option);
                        }} value={adressType}
                        disabled={isEditing === false}
                        id="ac"
                    />
                    {(adressTypeError !== '') && <p style={{ color: 'red', fontWeight: 'bold' }}>{adressTypeError}</p>}
                    {adressType === 'AA' && <Adress isEditing={isEditing} innerRef={adressRef} formData={adressData} handleFormData={handleAdressData} />}
                </Row>
            }

            {(error !== '') && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
            <div className="d-flex flex-row justify-content-between mt-3 mb-3">
                <Button className="d-flex" onClick={handlePrevStep}>Pasul anterior</Button>
                <Button className="d-flex" onClick={handleNextStep}>Pasul următor</Button>
            </div>
        </div>
    );
};

export default ReprezentantLegal;