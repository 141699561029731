import React, {Fragment, useEffect, useState} from 'react';
import Steps from '@components/prosumatori/components/Steps';
import ClientFinal from '@components/prosumatori/steps/ClientFinal';
import ReprezentantLegal from '@components/prosumatori/steps/ReprezentantLegal';
import AdresaObiectiv from '@components/prosumatori/steps/AdresaObiectiv';
import InformatiiTehniceLC from '@components/prosumatori/steps/InformatiiTehniceLC';
import LPNou from '@components/prosumatori/steps/LPNou';
import {stepsArray} from '@constants/prosumatori/Steps';
import DocumenteAnexate from '@components/prosumatori/steps/DocumenteAnexate';
import InformareFinala from '@components/prosumatori/steps/InformareFinala';
import {File} from '@components/common/UploadFile/UploadFile';
import LPExistent from '@components/prosumatori/steps/LPExistent';
import LPNotificare1 from '@components/prosumatori/steps/LPNotificare1';
import LPNotificare2 from '@components/prosumatori/steps/LPNotificare2';
import {
    getStepFive,
    getStepFour,
    getStepOne,
    getStepSeven,
    getStepSix,
    getStepThree,
    getStepTwo,
    getTypes, getUserDetails
} from '@api/prosumatori/prosumatori';
import {useDispatch, useSelector} from 'react-redux';
import {toggleLoader} from '@actions/LoaderActions';
import ChoiceModal from '@components/common/Modal/ChoiceModal';
import {IState} from '@type/store';
import {jwtDecode} from 'jwt-decode';
import { Button } from 'react-bootstrap';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
  }

const modalData = {
    title: 'Pentru cine dorești să depui cererea?',
    options: [
        {title: 'Vreau să depun o cerere în numele meu', id: 1},
        {title: 'Vreau să depun o cerere în numele altcuiva', id: 2}
    ]
};

const dateIde = {
    Nume: '',
    Prenume: '',
    CNP: '',
    Denumire: '',
    CUI: '',
    NrReg: '',
    Telefon: '',
    Email: '',
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    Cont: '',
    Banca: '',
    Reprezentant: '',
    InCalitateDe: '',
    SerieCI: '',
    NumarCI: '',
    CIEliberatDe: '',
    CIData: '',
    CIDataExp: ''
};

const adr = {
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
};

const imp = {
    Nume: '',
    Prenume: '',
    CNP: '',
    Denumire: '',
    CUI: '',
    Telefon: '',
    Email: '',
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    NrAutorizare: '',
    ActEmisDe: '',
    SerieCI: '',
    NumarCI: '',
    CIEliberatDe: '',
    CIData: '',
    CIDataExp: '',
    NrReg: ''
};

const adresaObiectivului = {
    POD: '',
    Judet: '',
    Localitate: '',
    Comuna: '',
    Strada: '',
    Numar: '',
    Bloc: '',
    Etaj: '',
    Scara: '',
    Ap: '',
    nrCF: '',
    nrCad: '',
    cod_loc_consum: '',
};

const dateTehniceLC = {
    seKW: '',
    seKVA: '',
    seTipRacord: 0,
    ssKW: '',
    ssKVA: '',
    ssTipRacord: 0,
};

const listaRec = {
    columns: [
        {
            name: 'Denumire',
            type: 'input',
            dataType: 'string',
            colName: 'Denumire',
            width: '320px'
        },
        {
            name: 'Putere instalata',
            type: 'input',
            dataType: 'numeric',
            colName: 'Putere instalată (kW)',
            width: '150px'
        },
        {
            name: 'Observatii',
            type: 'input',
            dataType: 'string',
            colName: 'Observații',
            width: '320px'
        }
    ],
    totalCol: [1],
    handledCols: [1]
};

const dateTehniceLP = {
    seKW: '',
    seKVA: '',
    ssKW: '',
    ssKVA: '',
};

const statiiInc = {
    columns: [
        {
            name: 'Denumire statie de incarcare masini electrice',
            type: 'input',
            dataType: 'string',
            colName: 'Denumire stație de încărcare mașini electrice',
        },
        {
            name: 'Nr. statii',
            type: 'input',
            dataType: 'integer',
            colName: 'Nr. stații',
        },
        {
            name: 'Putere maxima instalata (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Puterea maximă instalată pentru toate stațiile de același tip (kW)',
        },
        {
            name: 'Putere maxima simultan absorbita (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Putere maximă simultan absorbită (kW)',
        },
        {
            name: 'Tip statie (lenta/rapida)',
            type: 'select',
            options: [{name: 'lenta', id: 1}, {name: 'rapida', id: 2}],
            colName: 'Tip stație (lentă/rapidă)',
        },
        {
            name: 'Acces statie (public / privat)',
            type: 'select',
            options: [{name: 'privat', id: 1}, {name: 'public', id: 2}],
            colName: 'Acces stație (public / privat)',
        },
        {
            name: 'Sursa de finantare',
            type: 'select',
            options: [{name: 'FORTE PROPRII', id: 1}, {name: 'AFM2021-UAT SI INSTIT.PUBLICE', id: 2}, {name: 'ELECTRIC UP', id: 2}],
            colName: 'Sursa de finanțare',
        },
        {
            name: 'Observatii',
            type: 'input',
            dataType: 'string',
            colName: 'Observații'
        }
    ],
    totalCol: [1,2,3],
    handledCols: [2],
};

const dateGeneratoare = {
    columns: [
        {
            name: 'Nr. UG',
            type: 'input',
            dataType: 'integer',
            colName: 'Nr. UG',
            info: 'Nr. UG = număr unitate generatoare',
        },
        {
            name: 'Tip UG (As,S)',
            type: 'select',
            options: [{name: 'Asincron', id: 1}, {name: 'Sincron', id: 2}],
            colName: 'Tip UG (As,S)',
            info: 'Tip UG (As,S) = tip de unitate generatoare. As – Asincron, S- sincron'
        },
        {
            name: 'Tip UG (T,H,E)',
            type: 'select',
            options: [{name: 'Termo', id: 1}, {name: 'Hidro', id: 2}, {name: 'Eolian', id: 3}],
            colName: 'Tip UG (T,H,E)',
            info: 'Tip UG (T, H, E) = tip de unitate generatore. T- termo; H- hidro; E- eolian'
        },
        {
            name: 'U (V)',
            type: 'input',
            dataType: 'numeric',
            colName: 'U (V)',
        },
        {
            name: 'Un UG (V)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Un UG (V)',
            info: 'Un = tensiune nominală la borne'
        },
        {
            name: 'Pn UG (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pn UG (kW)',
            info: 'Pn = putere activă nominală'
        },
        {
            name: 'Sn UG (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Sn UG (kW)',
            info: 'Sn = putere aparentă nominală'
        },
        {
            name: 'Pi total (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pi total (kW)',
            info: 'Pi = putere activă instalată'
        },
        {
            name: 'Pmax produsa de UG (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pmax produsă de UG (kW)',
            info: 'Pmax = putere activă maximă'
        },
        {
            name: 'Pmin produsa de UG (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pmin produsă de UG (kW)',
            info: 'Pmin = putere activă minimă'
        },
        {
            name: 'Qmax (kVAr)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Qmax (kVAr)',
            info: 'Qmax- putere reactivă maximă'
        },
        {
            name: 'Qmin (kVAr)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Qmin (kVAr)',
            info: 'Qmin - putere reactivă minimă'
        },
        {
            name: 'Sevac (kVA)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Sevac (kVA)',
            info: 'Sevac = puterea aparentă aprobată pentru evacuare în rețea'
        },
        {
            name: 'Observatii',
            type: 'input',
            dataType: 'string',
            colName: 'Observatii'
        },
    ],
    totalCol: [0, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    handledCols: [0, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
};

const dateMijloaceComp = {
    columns: [
        {
            name: 'Tip echipament de compensare',
            type: 'input',
            dataType: 'string',
            colName: 'Tip echipament de compensare'
        },
        {
            name: 'Qn (kVAr)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Qn (kVAr)',
            info: 'Qn = putere reactivă nominală'
        },
        {
            name: 'Qmin (kVAr)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Qmin (kVAr)',
            info: 'Qmin = putere reactivă minimă'
        },
        {
            name: 'Qmax (kVAr)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Qmax (kVAr)',
            info: 'Qmax = putere reactivă maximă'
        },
        {
            name: 'Nr. trepte ***)',
            type: 'input',
            dataType: 'integer',
            colName: 'Nr. trepte',
            info: 'Nr de trepte*** = Se completează dacă tipul de echipament de compensare utilizat are reglaj în trepte'
        },
        {
            name: 'Observatii',
            type: 'input',
            dataType: 'string',
            colName: 'Observatii'
        },
    ],
    totalCol: [1, 2, 3, 4],
    handledCols: [1, 2, 3, 4]
};

const dateFotovoltaice = {
    columns: [
        {
            name: 'Nr. panouri',
            type: 'input',
            dataType: 'integer',
            colName: 'Nr. panouri'
        },
        {
            name: 'Tip Panou',
            type: 'input',
            dataType: 'string',
            colName: 'Tip Panou'
        },
        {
            name: 'Pi panou (c.c.) (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pi panou (c.c.) (kW)'
        },
        {
            name: 'Pi total panouri (c.c.) (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pi total panouri (c.c.) (kW)'
        },
        {
            name: 'Pmax debitat de panouri (c.c.) (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pmax debitat de panouri (c.c.) (kW)'
        },
        {
            name: 'Pi total panouri pe 1 ivnertor (c.c.) (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pi total panouri pe 1 ivnertor (c.c.) (kW)'
        },
        {
            name: 'Observatii',
            type: 'input',
            dataType: 'string',
            colName: 'Observatii'
        },
    ],
    totalCol: [0, 2, 3, 4, 5],
    handledCols: [0, 2, 3, 4, 5]
};

const dateInvertoare = {
    columns: [
        {
            name: 'Nr invertoare',
            type: 'input',
            dataType: 'integer',
            colName: 'Nr invertoare'
        },
        {
            name: 'Tipul invertoarelor ***)',
            type: 'input',
            dataType: 'string',
            colName: 'Tipul invertoarelor'
        },
        {
            name: 'Un invertor (c.a.) (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Un invertor (c.a.) (kW)'
        },
        {
            name: 'Pi invertor (c.a.) (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pi invertor (c.a.) (kW)'
        },
        {
            name: 'Pmax invertor evacuata in retea (c.a.) (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pmax invertor evacuata in retea (c.a.) (kW)'
        },
        {
            name: 'Pmax centrala formata din module generatoare (c.a.) (kW)',
            type: 'input',
            dataType: 'numeric',
            colName: 'Pmax centrala formata din module generatoare (c.a.) (kW)'
        },
        {
            name: 'Observatii',
            type: 'input',
            dataType: 'string',
            colName: 'Observatii'
        }
    ],
    totalCol: [0, 2, 3, 4, 5],
    handledCols: [0, 2, 3, 4, 5]
};

const dateEchipamenteMasurare = {
    columns: [
        {name: 'Serie contor', type: 'input', dataType: 'string', colName: 'Serie contor'},
        {name: 'Tip contor', type: 'input', dataType: 'string', colName: 'Tip contor'},
        {name: 'Date tehnice', type: 'input', dataType: 'string', colName: 'Date tehnice', customTd: true}]
};

const CerereProsumatori = (props: any) => {
    const [requestId, setRequestId] = useState<number | null>(null);
    const [isEditing, setIsEditing] = useState<boolean | null>(null);
    const [initiated, setInitiated] = useState<boolean>(false);
    // If request does not exist
    const [noRequest, setNoRequest] = useState(false);
    const [isFinalized, setIsFinalized] = useState(false);

    const OLD_SITE_URL: string | undefined = useSelector<IState>((state) => state.options.webApiHost) as string;
    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    let jwt: JWT = jwtDecode(token);

    const dispatch = useDispatch();

    const handleRequestId = (id: number) => {
        setRequestId(id);
    };

    const [showStartingModal, setShowStartingModal] = useState(true);
    const [startingModalOption, setStartingModalOption] = useState<number | null>(null);
    const [steps, setSteps] = useState<any[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [progress, setProgress] = useState(0);

    const [tipCerere, setTipCerere] = useState('');

    const handleTipCerere = (value: string) => {
        setTipCerere(value);
    };

    // const [UGTypeTHEOptions, setUGTypeTHEOptions] = useState([]);
    // const [UGTypeASOptions, setUGTypeASOptions] = useState([]);

    // const [reqId, setReqId] = useState(-1);
    
    const setStepOneFormData = async () => {
        dispatch(toggleLoader(true));

        await getUserDetails()
            .then((response) => {
                if (jwt.groups.includes('ClientFizic')) {
                    handleDateIdentificare(response.contactFirstName, 'Prenume');
                    handleDateIdentificare(response.contactLastName, 'Nume');
                    handleDateIdentificare(response.email, 'Email');
                    // handleDateIdentificare(response.mobilePhone, 'Telefon');
                } else if (jwt.groups.includes('ClientJuridic')) {
                    handleDateIdentificare(response.companyName, 'Denumire');
                    handleDateIdentificare(response.cui, 'CUI');
                    handleDateIdentificare(response.orc, 'NrReg');
                    handleDateIdentificare(response.email, 'Email');
                    // handleDateIdentificare(response.mobilePhone, 'Telefon');
                }

                setStartingModalOption(1);
                stepsArray.splice(1, 1);
                setSteps(stepsArray);
                setCurrentStep(0);
                currentStep === progress && setProgress(0);

                setShowStartingModal(false);
            })
            .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const setStepTwoFormData = async () => {
        dispatch(toggleLoader(true));

        await getUserDetails()
            .then((response) => {
                if (jwt.groups.includes('ClientFizic')) {
                    handleImputernicit(response.contactFirstName, 'Prenume');
                    handleImputernicit(response.contactLastName, 'Nume');
                    handleImputernicit(response.email, 'Email');
                    // handleImputernicit(response.mobilePhone, 'Telefon');
                } else if (jwt.groups.includes('ClientJuridic')) {
                    handleImputernicit(response.companyName, 'Denumire');
                    handleImputernicit(response.cui, 'CUI');
                    handleImputernicit(response.orc, 'NrReg');
                    handleImputernicit(response.email, 'Email');
                    // handleImputernicit(response.mobilePhone, 'Telefon');
                }

                setStartingModalOption(2);
                setSteps(stepsArray);
                setCurrentStep(0);
                currentStep === progress && setProgress(0);
  
                setShowStartingModal(false);
            })
            .catch((err) => console.log(err));

        dispatch(toggleLoader(false));
    };

    const handleCloseModal = (option: any) => {
        if (option.id === 1) {
            setStepOneFormData();
        } else {
            setStepTwoFormData();
        }
    };

    const handleStepChange = (step: number) => {
        setCurrentStep(step);
    };

    const handleProgress = (step: number) => {
        setProgress(step);
    };

    // Client final
    const [clientType, setClientType] = useState('');
    const [dateIdentificare, setDateIdentificare] = useState<any>(dateIde);
    const [adressType, setAdressType] = useState('');
    const [adress, setAdress] = useState(adr);

    const handleClientType = (type: string) => {
        setClientType(type);
    };

    const handleAdressType = (type: string) => {
        setAdressType(type);
    };

    const handleDateIdentificare = (data: any, field: any) => {
        let newData = dateIdentificare;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setDateIdentificare(newData);
    };

    const handleAdressData = (data: any, field: any) => {
        let newData = adress;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setAdress(newData);
    };

    // Reprezentant legal
    const [tipImputernicit, setTipImputernicit] = useState('');
    const [imputernicit, setImputernicit] = useState<object | null>(imp);

    const handleTipImputernicit = (type: string) => {
        setTipImputernicit(type);
    };

    const handleImputernicit = (data: any, field: any) => {
        let newData = imputernicit;

        newData && Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setImputernicit(newData);
    };

    // Adresa obiectiv si tip de solicitare
    const [tipLC, setTipLC] = useState<number | null>(null);
    const [tipAdresa, setTipAdresa] = useState<string>('');
    const [wasDataVerified, setWasDataVerified] = useState(false);
    const [nrAviz, setNrAviz] = useState('');
    const [dataAviz, setDataAviz] = useState('');
    const [tipLProd, setTipLProd] = useState<number | null>(null);
    const [sursaFinantare, setSursaFinantare] = useState<number | null>(null);
    const [sporPutere, setSporPutere] = useState<boolean | null>(null);
    const [modificariInst, setModificariInst] = useState<boolean | null>(null);
    const [solicit, setSolicit] = useState<any[]>([]);
    const [adresaOb, setAdresaOb] = useState(adresaObiectivului);
    const [numeLC, setNumeLC] = useState('');

    const handleTipLC = (type: number) => {
        setTipLC(type);

        let newData = adresaOb;

        Object.keys(newData).forEach(k => {
            // @ts-ignore
            newData[k] = '';
        });

        setAdresaOb(newData);
        setWasDataVerified(false);
        type === 1 && setTipLProd(1);
        type === 1 && setSporPutere(false);
        type === 1 && setModificariInst(false);
    };

    const handleNumeLC = (name: string) => {
        setNumeLC(name);
    };

    const handleTipAdresa = (type: string, fromStep?: false) => {
        if (type === 'AU') {
            let adresa = adresaOb;

            adresa.Judet = dateIdentificare.Judet;
            adresa.Localitate = dateIdentificare.Localitate;
            adresa.Comuna = dateIdentificare.Comuna;
            adresa.Strada = dateIdentificare.Strada;
            adresa.Numar = dateIdentificare.Numar;
            adresa.Bloc = dateIdentificare.Bloc;
            adresa.Etaj = dateIdentificare.Etaj;
            adresa.Scara = dateIdentificare.Scara;
            adresa.Ap = dateIdentificare.Ap;

            setAdresaOb(adresa);
        } else if (type === 'AA' && fromStep) {
            let adresa = adresaOb;

            adresa.Judet = '';
            adresa.Localitate = '';
            adresa.Comuna = '';
            adresa.Strada = '';
            adresa.Numar = '';
            adresa.Bloc = '';
            adresa.Etaj = '';
            adresa.Scara = '';
            adresa.Ap = '';

            setAdresaOb(adresa);
        }
        setTipAdresa(type);
    };

    const handleWasDataVerified = (value: boolean) => {
        setWasDataVerified(value);
    };

    const handleNrAviz = (value: string) => {
        setNrAviz(value);
    };

    const handleDataAviz = (value: any, fromResponse?: boolean) => {
        if (fromResponse) {
            setDataAviz(value);
        } else {
            setDataAviz(value.value);
        }
    };

    const handleAdresaOb = (data: any, field: any) => {
        let newData = adresaOb;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setAdresaOb(newData);
    };

    const handleTipLProd = (type: number) => {
        setTipLProd(type);
    };

    const handleSursaFinantare = (type: number) => {
        setSursaFinantare(type);
    };

    const handleSporPutere = (type: boolean) => {
        setSporPutere(type);
    };

    const handleModificariInst = (type: boolean) => {
        setModificariInst(type);
    };

    const handleSolicit = (data: any) => {
        setSolicit([...data]);
    };

    // Calculare tip cerere
    const [requestType, setRequestType] = useState('');

    const handleRequestType = () => {
        if (tipLC === 2 && tipLProd === 1 && sursaFinantare === 1 && sporPutere === false) {
            setRequestType('N1');
        } else if (tipLC === 2 && tipLProd === 2 && sursaFinantare === 1 && sporPutere === false && modificariInst === true) {
            setRequestType('N1');
        } else if (tipLC === 2 && tipLProd === 2 && sursaFinantare === 1 && sporPutere === false && modificariInst === false) {
            setRequestType('N2');
        } else {
            setRequestType('');
        }
    };

    // Informatii tehnice loc de consum
    const [dataSolicitata, setDataSolicitata] = useState<string>('');
    const [dateTehnice, setDateTehnice] = useState(dateTehniceLC);
    const [modAlimentare, setModAlimentare] = useState('');
    // const [hasNr, setHasNr] = useState(false);
    //eslint-disable-next-line
    const [nrAvizTehnic, setNrAvizTehnic] = useState('');
    const [tipTarif, setTipTarif] = useState<number | null>(null);
    const [tipTensiune, setTipTensiune] = useState<string | null>(null);
    const [tensiune, setTensiune] = useState<string>('0');
    const [tipFactorPutere, setTipFactorPutere] = useState<string | null>('0,9');
    const [factorPutere, setFactorPutere] = useState<string>('0,9');
    const [specificulActivitatii, setSpecitficulActivitatii] = useState<any[]>([]);
    const [otherSA, setOtherSA] = useState<string>('');
    const [regimLucru, setRegimLucru] = useState({
        nrSchimburi: '',
        interval: '',
        nrZile: ''
    });
    const [surseDeAlimentare, setSurseDeAlimentare] = useState({
        tip: '',
        putere: '',
        timpPornire: '',
        durataFunc: '',
    });
    const [timpIntrerupere, setTimpIntrerupere] = useState<string>('');
    //eslint-disable-next-line
    const [listaReceptoare, setListaReceptoare] = useState(listaRec);
    const [dateLR, setDateLR] = useState([]);
    //eslint-disable-next-line
    const [statiiIncarcare, setStatiiIncarcare] = useState(statiiInc);
    const [SI, setSI] = useState<number | null>(null);
    const [dateSI, setDateSI] = useState([]);
    //eslint-disable-next-line
    const [PIValue, setPIValue] = useState(0);
    const [totalPI, setTotalPI] = useState(0);

    const handleSI = (value: any) => {
        setSI(value);

        handleTotalPI();
    };

    const handleDataSolicitata = (value: any) => {
        setDataSolicitata(value.value);
    };

    const handleModAlimentare = (value: string) => {
        setModAlimentare(value);
    };

    const handleDateTehnice = (data: any, field: string) => {
        let newData = dateTehnice;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;

                if (key === 'seKW') {
                    console.log(data);
                    let seKVA = Number(newData.seKW.replace(',', '.')) / Number(factorPutere.replace(',', '.'));
                    let twoDecimals = seKVA.toFixed(2);
                    newData.seKVA = twoDecimals;
                }

                if (key === 'ssKW') {
                    let ssKVA = Number(newData.ssKW.replace(',', '.')) / Number(factorPutere.replace(',', '.'));
                    let twoDecimals = ssKVA.toFixed(2);
                    newData.ssKVA = twoDecimals;
                }

                if (key === 'seTipRacord' && newData[key] === 1 && tipLC === 1) {
                    handleTipTarif(1);
                    handleTipTensiune('0.23');
                }

                if (key === 'ssTipRacord' && newData[key] === 1 && tipLC === 2) {
                    handleTipTarif(1);
                    handleTipTensiune('0.23');
                }
            }
        });

        setDateTehnice({...newData});
    };

    const handleTipTarif = (value: number) => {
        setTipTarif(value);
    };

    const handleTensiune = (value: string) => {
        setTensiune(value);
    };

    const handleTipTensiune = (value: string) => {
        value !== '' && setTensiune(value);
        value === '' && setTensiune('0');
        setTipTensiune(value);
    };

    const handleFactorPutere = (value: string) => {
        let newData = dateTehnice;

        Object.keys(newData).map((key: string, index) => {
            if (key === 'seKW') {
                let seKVA = Number(newData.seKW.replace(',', '.')) / Number(value.replace(',', '.'));
                let twoDecimals = seKVA.toFixed(2);
                if (twoDecimals !== 'Infinity' && twoDecimals !== 'NaN') {
                    newData.seKVA = twoDecimals;
                } else {
                    newData.seKVA = '';
                }

                let ssKVA = Number(newData.ssKW.replace(',','.')) / Number(value.replace(',','.'));
                let tD = ssKVA.toFixed(2);
                if (tD !== 'Infinity' && tD !== 'NaN') {
                    newData.ssKVA = tD;
                } else {
                    newData.ssKVA = '';
                }
            }
        });

        setDateTehnice({...newData});
        setFactorPutere(value);
    };

    const handleTipFactorPutere = (value: string) => {
        value !== '' && setFactorPutere(value);
        value === '' && setFactorPutere('0');
        value === '0,9' && handleFactorPutere('0,9');
        setTipFactorPutere(value);
    };

    const handleSpecificulActivitatii = (data: any) => {
        setSpecitficulActivitatii([...data]);
    };

    const handleOtherSA = (value: string) => {
        setOtherSA(value);
    };

    const handleRegimLucru = (data: string, field: string) => {
        let newData = regimLucru;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setRegimLucru({...newData});
    };

    const handleSurseDeAlimentare = (data: string, field: string) => {
        let newData = surseDeAlimentare;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
            }
        });

        setSurseDeAlimentare({...newData});
    };

    const handleTimpIntrerupere = (value: string) => {
        setTimpIntrerupere(value);
    };

    const handleDateLR = (data: any) => {
        setDateLR(data);
    };

    const handleDateSI = (data: any) => {
        setDateSI(data);
    };

    const handleTotalPI = () => {
        let newTotal = 0;

        dateLR.map((row: any, index: number) => {
            Object.keys(row).map((key, idx) => {
                if (listaReceptoare.handledCols.includes(idx)) {
                    newTotal += Number(row[key]);
                }
            });
        });

        SI === 1 && dateSI.map((row: any, index: number) => {
            Object.keys(row).map((key, idx) => {
                if (statiiIncarcare.handledCols.includes(idx)) {
                    newTotal += Number(row[key]);
                }
            });
        });

        let t = Number(newTotal.toFixed(2));

        if (!isNaN(t)) {
            setTotalPI(t);
        } else {
            setTotalPI(0);
        }
    };

    const handleTotalPIUser = (value: number) => {
        setTotalPI(value);
    };

    useEffect(() => {
        handleTotalPI();
    }, [dateLR, dateSI, SI]);

    // Afisarea tipului locului de producere in functie de alegerile de la pasii anteriori

    const renderLPNou = () => {
        return <LPNou
            isEditing={isEditing}
            requestId={requestId}
            setStep={handleStepChange}
            currentStep={currentStep}
            progress={progress}
            setProgress={handleProgress}
            dateTehniceLP={dateLP}
            handleDateTehniceLP={handleDateLP}
            generatoare={generatore}
            handleGeneratoare={handleGeneratoare}
            tabelDateGen={tabelDateGen}
            dateGen={dateGen}
            handleDateGen={handleDateGen}
            mijloaceComp={mijloaceComp}
            handleMijloaceComp={handleMijloaceComp}
            tabelMC={tabelDateMC}
            handleDateMC={handleDateMC}
            dateMC={dateMC}
            fotovoltaic={fotovoltaic}
            handleFotovoltaic={handleFotovoltaic}
            tabelDateFoto={tabelDateFoto}
            handleDateFoto={handleDateFoto}
            dateFoto={dateFoto}
            invertoare={invertoare}
            handleInvertoare={handleInvertoare}
            tabelDateInv={tabelDateInv}
            handleDateInv={handleDateInv}
            dateInv={dateInv}
            psi={psi}
            handlePSI={handlePSI}
            pmsa={pmsa}
            handlePMSA={handlePMSA}
            surseRegen={surseRegen}
            detaliiSchema={detaliiSchema}
            handleDetaliiSchema={handleDetaliiSchema}
            capacitate={capacitate}
            handleCapacitate={handleCapacitate}
            handleSurseRegen={handleSurseRegen}
            masurare={masurare}
            handleMasurare={handleMasurare}
            tabelDateEM={tabelDateEM}
            handleDateEM={handleDateEM}
            dateEM={dateEM}
        />;
    };

    const renderLPExistent = () => {
        return <LPExistent
            isEditing={isEditing}
            requestId={requestId}
            setStep={handleStepChange}
            currentStep={currentStep}
            progress={progress}
            setProgress={handleProgress}
            dateTehniceLP={dateLP}
            handleDateTehniceLP={handleDateLP}
            generatoare={generatore}
            handleGeneratoare={handleGeneratoare}
            tabelDateGen={tabelDateGen}
            dateGen={dateGen}
            handleDateGen={handleDateGen}
            mijloaceComp={mijloaceComp}
            handleMijloaceComp={handleMijloaceComp}
            tabelMC={tabelDateMC}
            handleDateMC={handleDateMC}
            dateMC={dateMC}
            fotovoltaic={fotovoltaic}
            handleFotovoltaic={handleFotovoltaic}
            tabelDateFoto={tabelDateFoto}
            handleDateFoto={handleDateFoto}
            dateFoto={dateFoto}
            invertoare={invertoare}
            handleInvertoare={handleInvertoare}
            tabelDateInv={tabelDateInv}
            handleDateInv={handleDateInv}
            dateInv={dateInv}
            psi={psi}
            handlePSI={handlePSI}
            pmsa={pmsa}
            handlePMSA={handlePMSA}
            surseRegen={surseRegen}
            detaliiSchema={detaliiSchema}
            handleDetaliiSchema={handleDetaliiSchema}
            capacitate={capacitate}
            handleCapacitate={handleCapacitate}
            handleSurseRegen={handleSurseRegen}
            masurare={masurare}
            handleMasurare={handleMasurare}
            tabelDateEM={tabelDateEM}
            handleDateEM={handleDateEM}
            dateEM={dateEM}
        />;
    };

    const renderLNotificare1 = () => {
        return <LPNotificare1
            isEditing={isEditing}
            requestId={requestId}
            setStep={handleStepChange}
            currentStep={currentStep}
            progress={progress}
            setProgress={handleProgress}
            dateTehniceLP={dateLP}
            handleDateTehniceLP={handleDateLPN1}
            generatoare={generatore}
            handleGeneratoare={handleGeneratoare}
            tabelDateGen={tabelDateGen}
            dateGen={dateGen}
            handleDateGen={handleDateGen}
            mijloaceComp={mijloaceComp}
            handleMijloaceComp={handleMijloaceComp}
            tabelMC={tabelDateMC}
            handleDateMC={handleDateMC}
            dateMC={dateMC}
            fotovoltaic={fotovoltaic}
            handleFotovoltaic={handleFotovoltaic}
            tabelDateFoto={tabelDateFoto}
            handleDateFoto={handleDateFoto}
            dateFoto={dateFoto}
            invertoare={invertoare}
            handleInvertoare={handleInvertoare}
            tabelDateInv={tabelDateInv}
            handleDateInv={handleDateInv}
            dateInv={dateInv}
            psi={psi}
            handlePSI={handlePSI}
            pmsa={pmsa}
            handlePMSA={handlePMSA}
            surseRegen={surseRegen}
            detaliiSchema={detaliiSchema}
            handleDetaliiSchema={handleDetaliiSchema}
            capacitate={capacitate}
            handleCapacitate={handleCapacitate}
            handleSurseRegen={handleSurseRegen}
            masurare={masurare}
            handleMasurare={handleMasurare}
            tabelDateEM={tabelDateEM}
            handleDateEM={handleDateEM}
            dateEM={dateEM}
        />;
    };

    const renderLNotificare2 = () => {
        return <LPNotificare2
            isEditing={isEditing}
            requestId={requestId}
            setStep={handleStepChange}
            currentStep={currentStep}
            progress={progress}
            setProgress={handleProgress}
            dateTehniceLP={dateLP}
            handleDateTehniceLP={handleDateLP}
            generatoare={generatore}
            handleGeneratoare={handleGeneratoare}
            tabelDateGen={tabelDateGen}
            dateGen={dateGen}
            handleDateGen={handleDateGen}
            mijloaceComp={mijloaceComp}
            handleMijloaceComp={handleMijloaceComp}
            tabelMC={tabelDateMC}
            handleDateMC={handleDateMC}
            dateMC={dateMC}
            fotovoltaic={fotovoltaic}
            handleFotovoltaic={handleFotovoltaic}
            tabelDateFoto={tabelDateFoto}
            handleDateFoto={handleDateFoto}
            dateFoto={dateFoto}
            invertoare={invertoare}
            handleInvertoare={handleInvertoare}
            tabelDateInv={tabelDateInv}
            handleDateInv={handleDateInv}
            dateInv={dateInv}
            psi={psi}
            handlePSI={handlePSI}
            pmsa={pmsa}
            handlePMSA={handlePMSA}
            surseRegen={surseRegen}
            detaliiSchema={detaliiSchema}
            handleDetaliiSchema={handleDetaliiSchema}
            capacitate={capacitate}
            handleCapacitate={handleCapacitate}
            handleSurseRegen={handleSurseRegen}
            masurare={masurare}
            handleMasurare={handleMasurare}
            tabelDateEM={tabelDateEM}
            handleDateEM={handleDateEM}
            dateEM={dateEM}
        />;
    };

    const renderLocProducere = () => {
        if (tipLC === 1 && tipLProd === 1 && sursaFinantare === 1) {
            console.log('Nou');
            return renderLPNou();
        } else if (tipLC === 1 && tipLProd === 1 && sursaFinantare === 2) {
            console.log('Nou');
            return renderLPNou();
        } else if (tipLC === 2 && tipLProd === 1 && sursaFinantare === 2) {
            console.log('Nou');
            return renderLPNou();
        } else if (tipLC === 2 && tipLProd === 2 && sursaFinantare === 2) {
            console.log('Existent');
            return renderLPExistent();
        } else if (tipLC === 2 && tipLProd === 1 && sursaFinantare === 1 && sporPutere === true) {
            console.log('Nou');
            return renderLPNou();
        } else if (tipLC === 2 && tipLProd === 1 && sursaFinantare === 1 && sporPutere === false) {
            console.log('N1');
            return renderLNotificare1();
        } else if (tipLC === 2 && tipLProd === 2 && sursaFinantare === 1 && sporPutere === true) {
            console.log('Existent');
            return renderLPExistent();
        } else if (tipLC === 2 && tipLProd === 2 && sursaFinantare === 1 && sporPutere === false && modificariInst === true) {
            console.log('N1');
            return renderLNotificare1();
        } else if (tipLC === 2 && tipLProd === 2 && sursaFinantare === 1 && sporPutere === false && modificariInst === false) {
            console.log('N2');
            return renderLNotificare2();
        }

        return renderLPNou();
    };

    // Informatii tehnice loc de producere
    const [dateLP, setDateLP] = useState(dateTehniceLP);
    const [generatore, setGeneratoare] = useState<number | null>(null);
    //eslint-disable-next-line
    const [tabelDateGen, setTabelDateGen] = useState(dateGeneratoare);
    const [dateGen, setDateGen] = useState([]);
    const [mijloaceComp, setMijloaceComp] = useState<number | null>(null);
    //eslint-disable-next-line
    const [tabelDateMC, setTabelDateMC] = useState(dateMijloaceComp);
    const [dateMC, setDateMC] = useState([]);
    const [fotovoltaic, setFotovoltaic] = useState<number | null>(null);
    //eslint-disable-next-line
    const [tabelDateFoto, setTabelDateFoto] = useState(dateFotovoltaice);
    const [dateFoto, setDateFoto] = useState([]);
    const [invertoare, setInvertoare] = useState<number | null>(null);
    //eslint-disable-next-line
    const [tabelDateInv, seTabeltDateInv] = useState(dateInvertoare);
    const [dateInv, setDateInv] = useState([]);
    const [psi, setPSI] = useState('');
    const [pmsa, setPMSA] = useState('');

    const [surseRegen, setSurseRegen] = useState<number | null>(0);
    const [detaliiSchema, setDetaliiSchema] = useState('');
    const [capacitate, setCapacitate] = useState('');

    const [masurare, setMasurare] = useState<number | null>(null);
    //eslint-disable-next-line
    const [tabelDateEM, setTabelDateEM] = useState(dateEchipamenteMasurare);
    const [dateEM, setDateEM] = useState([]);

    const handleDateLP = (data: any, field: string) => {
        let newData = dateLP;
        //eslint-disable-next-line
        let dateLC = dateTehnice;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;

                if (key === 'seKW') {
                    let seKVA = Number(newData.seKW.replace(',','.')) / Number(factorPutere.replace(',','.'));
                    let td = seKVA.toFixed(2);
                    newData.seKVA = td;
                }

                if (key === 'ssKW') {
                    let ssKVA = Number(newData.ssKW.replace(',','.')) / Number(factorPutere.replace(',','.'));
                    let td = ssKVA.toFixed(2);
                    newData.ssKVA = td;
                }
            }
        });

        setDateLP({...newData});
    };

    const handleDateLPN1 = (data: any, field: string) => {
        let newData = dateLP;

        Object.keys(newData).map((key: string, index) => {
            if (key === field) {
                //@ts-ignore
                newData[key] = data;
                let seKVA;

                if (key === 'seKW') {
                    console.log(factorPutere);
                    if (factorPutere !== null) {
                        seKVA = Number(newData.seKW.replace(',','.')) / Number(factorPutere.replace(',','.'));
                    } else {
                        seKVA = Number(newData.seKW.replace(',','.'));
                    }
                    let td = seKVA.toFixed(2);
                    newData.seKVA = td;
                }
            }
        });

        setDateLP({...newData});
    };

    const handleGeneratoare = (value: number) => {
        setGeneratoare(value);
    };

    const handleDateGen = (data: any) => {
        setDateGen(data);
    };

    const handleMijloaceComp = (value: number) => {
        setMijloaceComp(value);
    };

    const handleDateMC = (value: any) => {
        setDateMC(value);
    };

    const handleFotovoltaic = (value: number) => {
        setFotovoltaic(value);
    };

    const handleDateFoto = (value: any) => {
        setDateFoto(value);
    };

    const handleInvertoare = (value: number) => {
        setInvertoare(value);
    };

    const handleDateInv = (value: any) => {
        setDateInv(value);
    };

    const handlePSI = (value: string) => {
        setPSI(value);
    };

    const handlePMSA = (value: string) => {
        setPMSA(value);
    };

    const handleSurseRegen = (value: number) => {
        setSurseRegen(value);
    };

    const handleDetaliiSchema = (value: string) => {
        setDetaliiSchema(value);
    };

    const handleCapacitate = (value: string) => {
        setCapacitate(value);
    };

    const handleMasurare = (value: number) => {
        setMasurare(value);
    };

    const handleDateEM = (value: any) => {
        setDateEM(value);
    };

    // Documente anexate

    const [files, setFiles] = useState<any []>([]);
    const [wereFilesMounted, setWereFilesMounted] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([] as File[]);
    const [alreadyUploaded, setAlreadyUploaded] = useState<any[]>([]);

    const handleSetFiles = (files: File[]) => {
        let addEmptyFiles: any = [];

        files.map((f: any, i: number) => {
            addEmptyFiles.push([]);
        });

        setUploadedFiles(addEmptyFiles);
        setFiles(files);
    };

    const handleShowExtraFiles = (fileName: string) => {
        let newArray: any = [];
        let newItem: any = null;
        let index = null;

        files.map((f: any, i: number) => {
            let item = f;

            if (item.name === fileName) {
                // item.show = true;
                newItem = item;
                index = i;
            }

            newArray.push(item);
        });

        newItem.show = true;
        newArray.splice(index, 1, newItem);

        setFiles(newArray);
    };

    const handleWereFilesMounted = (value: boolean) => {
        setWereFilesMounted(value);
    };

    const handleInitalFilesToUpload = (files: File[]) => {
        setUploadedFiles(files);
    };

    const uploadFile = (fileArr: File[], index: number, isMounting: boolean) => {
        let newData = uploadedFiles;

        newData.map((f: File, idx: number) => {
            if (idx === index) {
                newData[idx] = fileArr;
            }
        });

        setUploadedFiles([...newData]);
    };

    // Informare finala
    const [checkedOne, setCheckedOne] = useState(false);
    const [checkedTwo, setCheckedTwo] = useState(false);

    const handleCheckedOne = (value: boolean) => {
        setCheckedOne(value);
    };

    const handleCheckedTwo = (value: boolean) => {
        setCheckedTwo(value);
    };

    const renderModal = () => {
        return <ChoiceModal 
                    showModal={showStartingModal} 
                    modalTitle={modalData.title} 
                    options={modalData.options} 
                    onClose={handleCloseModal} 
                    onHide={() => window.location.href = `${OLD_SITE_URL}/solicitari`}
                >
                    <h5>Prosumatori</h5>
                </ChoiceModal>;
    };

    const stepOneGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepOne(payload)
            .then((response) => {
                if (response.cerere_finalizata === true) {
                    setIsFinalized(true);
                }

                let date = response.DateIdentificare;
                let adresa = response.DateIdentificare.Adresa;

                if (response.reprezentant === false) {
                    stepsArray.splice(1, 1);
                    setSteps(stepsArray);

                    if (step === 8) {
                        setCurrentStep(0);
                        setProgress(step-2);
                    } else if (step !== 1) {
                        setCurrentStep(step-2);
                        setProgress(step-2);
                    } else if (step === 1) {
                        setCurrentStep(0);
                        setProgress(0);
                    }

                    setStartingModalOption(1);
                } else {
                    setSteps(stepsArray);

                    if (step === 8) {
                        setCurrentStep(0);
                        setProgress(step-1);
                    } else if (step !== 1) {
                        setCurrentStep(step-1);
                        setProgress(step-1);
                    } else if (step === 1) {
                        setCurrentStep(0);
                        setProgress(0);
                    }

                    setStartingModalOption(2);
                }

                delete date['Adresa'];

                if (response.AdresaCorespondenta !== null) {
                    if (response.TipCorespondenta === 'AA') {
                        setAdress(response.AdresaCorespondenta);
                    }
                }

                setAdressType(response.TipCorespondenta);

                let dateID = {
                    Nume: date.Nume !== null ? date.Nume : '',
                    Prenume: date.Prenume !== null ? date.Prenume : '',
                    CNP: date.CNP !== null ? date.CNP : '',
                    CUI: date.CUI !== null ? date.CUI : '',
                    Denumire: date.Denumire !== null ? date.Denumire : '',
                    NrReg: date.NrReg !== null ? date.NrReg : '',
                    Telefon: date.Telefon !== null ? date.Telefon : '',
                    Email: date.Email !== null ? date.Email : '',
                    Cont: date.Cont !== null ? date.Cont : '',
                    Banca: date.Banca !== null ? date.Banca : '',
                    Reprezentant: date.Reprezentant !== null ? date.Reprezentant : '',
                    InCalitateDe: date.InCalitateDe !== null ? date.InCalitateDe : '',
                    SerieCI: date.SerieCI !== null ? date.SerieCI : '',
                    NumarCI: date.NumarCI !== null ? date.NumarCI : '',
                    CIEliberatDe: date.CIEliberatDe !== null ? date.CIEliberatDe : '',
                    CIData: date.CIData !== null ? date.CIData : '',
                    CIDataExp: date.CIDataExp !== null ? date.CIDataExp : '',
                };

                Object.assign(dateID, adresa);
                setDateIdentificare(dateID);
                setClientType(response.TipClient === 'PF' ? '1' : '2');
            })
            .catch((error) => {
                if (error === 'Request does not exists') {
                    setNoRequest(true);
                }
            });

        if (step === 1) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepTwoGET = async (payload: any, step: number) => {
        dispatch(toggleLoader(true));

        await getStepTwo(payload)
            .then((response) => {
                console.log(response);
                let date = response.Imputernicit;
                let adresa = response.Imputernicit.Adresa;

                delete date['Adresa'];
                Object.assign(date, adresa);

                if (date.CIData === null) {
                    date.CIData = '';
                };

                if (date.CIDataExp === null) {
                    date.CIDataExp = '';
                }

                if (date.NrReg === null) {
                    date.NrReg = '';
                }

                setImputernicit(date);
                setAdressType(response.TipCorespondenta);
                setTipImputernicit(response.TipImputernicit);
            })
            .catch((error) => {
                if ((error && error.StatusCode && error.StatusCode === 500) || (error && error.status && error.status === 204)) {
                    setImputernicit(null);
                }
            });

        if (step === 2) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepThreeGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepThree(payload)
            .then((response) => {
                if (response.Adresa !== null) {
                    let adr = response.Adresa;
                    adr = {...adr, nrCF: response.nrCF};
                    adr = {...adr, nrCad: response.nrCad};
                    adr = {...adr, POD: response.POD === null ? '' : response.POD};
                    adr = {...adr, cod_loc_consum: response.cod_loc_consum};

                    setAdresaOb(adr);
                } else {
                    getStepOne(payload).then((res) => {
                        let adresaObiectiv = {
                            Judet: res.DateIdentificare.Adresa.Judet,
                            Localitate: res.DateIdentificare.Adresa.Localitate,
                            Comuna: res.DateIdentificare.Adresa.Comuna,
                            Strada: res.DateIdentificare.Adresa.Strada,
                            Numar: res.DateIdentificare.Adresa.Numar,
                            Bloc: res.DateIdentificare.Adresa.Bloc,
                            Etaj: res.DateIdentificare.Adresa.Etaj,
                            Scara: res.DateIdentificare.Adresa.Scara,
                            Ap: res.DateIdentificare.Adresa.Ap,
                            nrCF: '',
                            nrCad: '',
                            POD: '',
                            cod_loc_consum: '',
                        };

                        setAdresaOb(adresaObiectiv);
                    });
                }

                if (response.POD !== null) {
                    setWasDataVerified(true);
                    setNrAviz(response.nr_aviz);
                    setDataAviz(response.data_aviz !== null ? response.data_aviz : '');
                }
                
                setNumeLC(response.nume_loc_consum !== null ? response.nume_loc_consum : '');
                setTipLC(response.TipLC);
                setTipLProd(response.TipLP);
                setTipAdresa(response.TipAdresa);
                setModificariInst(response.modificari);
                setSporPutere(response.sporPutere);
                setSursaFinantare(response.sursaFinantare);

                handleRequestType();

                getReasonType(['RequestReasonType'], 'EE').then((res) => {
                    let data = res;
                    console.log(res);

                    data.map((el: any, i: number) => {
                        el.active = false;

                        response.solicit.map((item: any, idx: number) => {
                            console.log(item);
                            if (el.id === item) {
                                el.active = true;
                            }
                        });
                    });

                    setSolicit(data);

                    if (step === 3) {
                        setShowStartingModal(false);
                    }
                });
            })
            .catch((error) => console.log(error));

        dispatch(toggleLoader(false));
    };

    const stepFourGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepFour(payload)
            .then((response) => {
                setNrAvizTehnic(response.nr_aviz);
                setModAlimentare(response.mod_alimentare !== null ? response.mod_alimentare : '');
                setDataSolicitata(response.data_solicitata);

                let date = {
                    seKW: response.date_tehnice_old.KW !== null ? '' + response.date_tehnice_old.KW : '',
                    seKVA: response.date_tehnice_old.KVA !== null ? '' + response.date_tehnice_old.KVA : '',
                    ssKW: response.date_tehnice_new.KW !== null ? '' + response.date_tehnice_new.KW : '',
                    ssKVA: response.date_tehnice_new.KVA !== null ? '' + response.date_tehnice_new.KVA : '',
                    seTipRacord: response.date_tehnice_old.TipRacord,
                    ssTipRacord: response.date_tehnice_new.TipRacord,
                };

                setDateTehnice(date);
                setTipTarif(response.tip_tarif);

                // let fp = response.factor_putere !== null ? response.factor_putere.toString() : null;

                // setTipFactorPutere('');
                // setFactorPutere(fp);
                // setTipTensiune('');
                // setTensiune('' + response.tensiune);

                let fp = response.factor_putere !== null ? response.factor_putere : null;

                fp = fp.toString();

                if (response.factor_putere === 0.9) {
                    setTipFactorPutere('0,9');
                } else {
                    setTipFactorPutere('');
                }

                setFactorPutere(fp);

                if (response.tensiune === 0.23 || response.tensiune === 0.4 || response.tensiune === 6 || response.tensiune === 20 || response.tensiune === 110) {
                    setTipTensiune('' + response.tensiune);
                    setTensiune('' + response.tensiune);
                } else {
                    setTipTensiune('');
                    setTensiune('' + response.tensiune);
                }

                let lista_rec = response.lista_receptoare;
                // lista_rec.map((row: any, index: number) => {
                //     Object.keys(row).map((key, idx) => {
                //         if (key === 'Putere_instalata') {
                //             row[key] = '' + row[key];
                //         }
                //     });
                // });
                lista_rec.forEach(function(v: any){ delete v.requestReceiverId; });
                lista_rec.forEach(function(v: any){ delete v.numar_receptoare; });
                lista_rec.forEach(function(v: any){ delete v.putere_maxim_absorbita; });
                lista_rec.forEach(function(v: any){ delete v.IsTotalLine; });
                setDateLR(lista_rec);

                setSI(response.statie_masina_electrica === false ? 2 : 1);
                let statii_inc = response.statii_incarcare;
                let delete_at_index = null;

                statii_inc.map((row: any, index: number) => {
                    Object.keys(row).map((key, idx) => {
                        if (row[key] === null) {
                            row[key] = '';
                        } else if (key === 'IsTotalLine') {
                            if (row[key] === true) {
                                delete_at_index = index;
                            }
                        } else if (key === 'Acces_statie (public / privat)') {
                            if (row[key] === 1) {
                                row[key] = 'privat';
                            } else if (row[key] === 2) {
                                row[key] = 'public';
                            }
                        } else if (key === 'Sursa_de finantare') {
                            if (row[key] === 1) {
                                row[key] = 'FORTE PROPRII';
                            } else if (row[key] === 2) {
                                row[key] = 'AFM2021-UAT SI INSTIT.PUBLICE';
                            } else if (row[key] === 3) {
                                row[key] = 'ELECTRIC UP';
                            }
                        } else if (key === 'Tip_statie (lenta/rapida)') {
                            if (row[key] === 1) {
                                row[key] = 'lenta';
                            } else if (row[key] === 2) {
                                row[key] = 'rapida';
                            }
                        }
                    });
                });

                statii_inc.splice(delete_at_index, 1);
                statii_inc.forEach(function(v: any){ delete v.requestChargingStationId; });
                statii_inc.forEach(function(v: any){ delete v.IsTotalLine; });
                setDateSI(statii_inc);

                let surse = {
                    tip: response.surse_alimentare.tip === null ? '' : '' + response.surse_alimentare.tip,
                    putere: response.surse_alimentare.putere === null ? '' : '' + response.surse_alimentare.putere,
                    timpPornire: response.surse_alimentare.timp === null ? '' : '' + response.surse_alimentare.timp,
                    durataFunc: response.surse_alimentare.durata === null ? '' : '' + response.surse_alimentare.durata,
                };
                setSurseDeAlimentare(surse);

                setTimpIntrerupere(response.timp_max_intrerupere === null ? '' : '' + response.timp_max_intrerupere);

                let regim = {
                    nrSchimburi: response.regim_lucru.nr_schimburi === null ? '' : '' + response.regim_lucru.nr_schimburi,
                    interval: response.regim_lucru.interval_orar === null ? '' : '' + response.regim_lucru.interval_orar,
                    nrZile: response.regim_lucru.nr_zile === null ? '' : '' + response.regim_lucru.nr_zile
                };
                setRegimLucru(regim);
                setTotalPI(response.pi_total);

                getOptionTypes(['ActivityType']).then((res) => {
                    let data = res;
                    data.map((el: any, i: number) => {
                        el.active = false;
                        response.specificul_activitatii.map((type: any, idx: number) => {
                            if (el.id === type.activityTypeId) {
                                el.active = true;

                                if (type.activityTypeId === 6) {
                                    setOtherSA(type.activityType);
                                }
                            }
                        });
                    });
                    setSpecitficulActivitatii(data);

                    if (step === 4) {
                        setShowStartingModal(false);
                    }
                });
            });

        dispatch(toggleLoader(false));

    };

    const stepFiveGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepFive(payload)
            .then((response) => {
                let date = {
                    seKW: response.date_tehnice_old.KW !== null ? '' + response.date_tehnice_old.KW : '',
                    seKVA: response.date_tehnice_old.KVA !== null ? '' + response.date_tehnice_old.KVA : '',
                    ssKW: response.date_tehnice_new.KW !== null ? '' + response.date_tehnice_new.KW : '',
                    ssKVA: response.date_tehnice_new.KVA !== null ? '' + response.date_tehnice_new.KVA : ''
                };

                setGeneratoare(response.exista_generatoare === false ? 2 : 1);
                let g = response.generatoare;
                let delete_g = null;
                g.map((row: any, index: number) => {
                    Object.keys(row).map((key, idx) => {
                        if (key === 'IsTotalLine') {
                            if (row[key] === true) {
                                delete_g = index;
                            }
                        }
                    });
                });
                g.splice(delete_g, 1);
                g.forEach(function(v: any){ delete v.requestPowerGeneratorId; });
                g.forEach(function(v: any){ delete v.IsTotalLine; });

                // getOptionTypes(['UGTypeTHE']).then((res) => {
                //     let data = res;
                //
                //     g.map((row: any, index: number) => {
                //         Object.keys(row).map((key, i) => {
                //             if (key === 'Tip_UG (As,S)') {
                //                 if (row[key] === 1) {
                //                     row[key] = 'Asincron';
                //                 } else if (row[key] === 2) {
                //                     row[key] = 'Sincron';
                //                 }
                //             } else if (key === 'Tip_UG (T,H,E)') {
                //                 if (row[key] === 1) {
                //                     row[key] = 'Termo';
                //                 } else if (row[key] === 2) {
                //                     row[key] = 'Hidro';
                //                 } else if (row[key] === 3) {
                //                     row[key] = 'Eolian';
                //                 }
                //             }
                //         });
                //     });
                // });

                g.map((row: any, index: number) => {
                    Object.keys(row).map((key, i) => {
                        if (key === 'Tip_UG (As,S)') {
                            if (row[key] === 1) {
                                row[key] = 'Asincron';
                            } else if (row[key] === 2) {
                                row[key] = 'Sincron';
                            }
                        } else if (key === 'Tip_UG (T,H,E)') {
                            if (row[key] === 1) {
                                row[key] = 'Termo';
                            } else if (row[key] === 2) {
                                row[key] = 'Hidro';
                            } else if (row[key] === 3) {
                                row[key] = 'Eolian';
                            }
                        }
                    });
                });
                console.log(g);
                setDateGen(g);

                setFotovoltaic(response.exista_fotovoltaice === false ? 2 : 1);
                let f = response.fotovoltaice;
                let delete_f = null;
                f.map((row: any, index: number) => {
                    Object.keys(row).map((key, idx) => {
                        if (key === 'IsTotalLine') {
                            if (row[key] === true) {
                                delete_f = index;
                            }
                        }
                        row[key] = '' + row[key];
                    });
                });
                f.splice(delete_f, 1);
                f.forEach(function(v: any){ delete v.requestSolarPanelId; });
                f.forEach(function(v: any){ delete v.IsTotalLine; });
                setDateFoto(f);

                setMasurare(response.exista_echipamente_masurare === false ? 2 : 1);
                let em = response.echipamente;
                em.map((row: any, index: number) => {
                    Object.keys(row).map((key, idx) => {
                        row[key] = '' + row[key];
                    });
                });
                em.forEach(function(v: any){ delete v.requestOtherEquipmentId; });
                setDateEM(em);

                setMijloaceComp(response.exista_mijl_comp === false ? 2 : 1);
                let mc = response.mijl_comp;
                mc.map((row: any, index: number) => {
                    Object.keys(row).map((key, idx) => {
                        row[key] = '' + row[key];
                    });
                });
                mc.forEach(function(v: any){ delete v.requestReactivePowerCompensationSolutionId; });
                setDateMC(mc);

                setInvertoare(response.exista_invertoare === false ? 2 : 1);
                let i = response.invertoare;
                let delete_i = null;
                i.map((row: any, index: number) => {
                    Object.keys(row).map((key, idx) => {
                        if (row[key] === null) {
                            row[key] = '';
                        } else if (key === 'IsTotalLine') {
                            if (row[key] === true) {
                                delete_i = index;
                            }
                        } else {
                            row[key] = '' + row[key];
                        }
                    });
                });
                i.splice(delete_i, 1);
                i.forEach(function(v: any){ delete v.IsTotalLine; });
                i.forEach(function(v: any){ delete v.requestInvertorId; });
                setDateInv(i);

                setSurseRegen(response.exista_surse_regen === false ? 2 : 1);
                setDetaliiSchema(response.surse_regen.detalii_schema_alimentare);
                if (response.surse_regen.capacitate_baterii === null) {
                    setCapacitate('');
                } else {
                    setCapacitate('' + response.surse_regen.capacitate_baterii);
                }
                setPSI('' + response.servicii_interne.psi);
                setPMSA('' + response.servicii_interne.pmsa);

                setDateLP(date);
            });

        if (step === 5) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepSixGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepSix(payload)
            .then((response) => {
                setAlreadyUploaded(response.requestDocuments);
            });

        if (step === 6) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const stepSevenGET = async (payload: any, step: any) => {
        dispatch(toggleLoader(true));

        await getStepSeven(payload)
            .then((response) => {
                setCheckedOne(response.Informare_1);
                setCheckedTwo(response.Informare_2);
            });

        if (step === 7) {
            setShowStartingModal(false);
        }

        dispatch(toggleLoader(false));
    };

    const reloadAlreadyUploadedDocuments = async () => {
        dispatch(toggleLoader(true));

        await getStepSix({requestId: requestId})
            .then((response) => {
                setAlreadyUploaded(response.requestDocuments);
            });

        dispatch(toggleLoader(false));
    };

    const initiateRequest = () => {
        console.log('initiate');
        // const url = new URL('http://localhost:3000/cerere-prosumatori?requestId=1553&step=Pas6&edit=true');
        // const url = new URL('http://localhost:3000');
        const url = new URL(window.location.href);
        const params = url.search;
        const searchParams = new URLSearchParams(params);

        if (searchParams.has('requestId')) {
            let req_Id = searchParams.get('requestId');
            let stepProgress = searchParams.get('step');
            let edit = searchParams.get('edit');

            if (stepProgress) {
                stepProgress = stepProgress.slice(3);
            }

            let p = Number(stepProgress);
            let reqId = Number(req_Id);

            setIsEditing(edit === 'true');
            // setReqId(reqId);

            p >= 1 && stepOneGET({requestId: reqId}, p);

            setCurrentStep(0);
            setProgress(0);

            p >= 2 && stepTwoGET({requestId: reqId}, p);
            p >= 3 && stepThreeGET({requestId: reqId}, p);
            p >= 4 && stepFourGET({requestId: reqId}, p);
            p >= 5 && stepFiveGET({requestId: reqId}, p);
            p >= 6 && stepSixGET({requestId: reqId}, p);
            p >= 7 && stepSevenGET({requestId: reqId}, p);

            if (p === 8) {
                setShowStartingModal(false);
            }

            setRequestId(reqId);

            // setShowStartingModal(false);
        }

        setInitiated(true);
    };

    const getOptionTypes = async (keys: string[], utility?: string) => {
        return await getTypes(keys, utility).then((res: any) => {
            return res[keys[0]];
        });
    };

    const getReasonType = async (keys: string[], utility?: string) => {
        return await getTypes(keys, utility).then((res: any) => {
            return res.requestreasontype;
        });
    };

    useEffect(() => {
        if (initiated === false) {
            initiateRequest();
        }
    }, []);

    const renderSteps = () => {
        return <Fragment>
            <Steps steps={steps} currentStep={currentStep} changeStep={handleStepChange} progress={progress} isEditing={isEditing} />
            {currentStep === 0 &&
                <ClientFinal
                    modalOption={startingModalOption}
                    isEditing={isEditing}
                    requestId={requestId}
                    handleRequestId={handleRequestId}
                    startingModalOption={startingModalOption}
                    handleFormData={handleDateIdentificare}
                    formData={dateIdentificare}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    clientType={clientType}
                    handleClientType={handleClientType}
                    adressType={adressType}
                    handleAdressType={handleAdressType}
                    adressData={adress}
                    handleAdressData={handleAdressData}
                />
            }
            {(currentStep === 1 && startingModalOption === 2) &&
                <ReprezentantLegal
                    isEditing={isEditing}
                    requestId={requestId}
                    startingModalOption={startingModalOption}
                    tipImputernicit={tipImputernicit}
                    handleTipImputernicit={handleTipImputernicit}
                    handleFormData={handleImputernicit}
                    formData={imputernicit}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    adressData={adress}
                    handleAdressData={handleAdressData}
                    adressType={adressType}
                    handleAdressType={handleAdressType}
                    dateId={dateIdentificare}
                />
            }
            {((currentStep === 2 && startingModalOption === 2) || (currentStep === 1 && startingModalOption === 1)) &&
                <AdresaObiectiv
                    tipCerere={tipCerere}
                    handleTipCerere={handleTipCerere}
                    handleRequestType={handleRequestType}
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    tipLC={tipLC}
                    handleTipLC={handleTipLC}
                    tipAdresa={tipAdresa}
                    handleTipAdresa={handleTipAdresa}
                    nrAviz={nrAviz}
                    handleNrAviz={handleNrAviz}
                    dataAviz={dataAviz}
                    handleDataAviz={handleDataAviz}
                    dateUser={dateIdentificare}
                    wasDataVerified={wasDataVerified}
                    handleWasDataVerified={handleWasDataVerified}
                    adresaOb={adresaOb}
                    handleAdresaOb={handleAdresaOb}
                    tipLProd={tipLProd}
                    handleTipLProd={handleTipLProd}
                    sursaFinantare={sursaFinantare}
                    handleSursaFinantare={handleSursaFinantare}
                    sporPutere={sporPutere}
                    handleSporPutere={handleSporPutere}
                    modificariInst={modificariInst}
                    handleModificariInst={handleModificariInst}
                    solicit={solicit}
                    handleSolicit={handleSolicit}
                    numeLC={numeLC}
                    handleNumeLC={handleNumeLC}
                    dateIdentificare={dateIdentificare}
                />
            }
            {((currentStep === 3 && startingModalOption === 2) || (currentStep === 2 && startingModalOption === 1)) &&
                <InformatiiTehniceLC
                    requestType={requestType}
                    handleRequestType={handleRequestType}
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    tipLC={tipLC}
                    adresaObiectiv={adresaOb}
                    modAlimentare={modAlimentare}
                    handleModAlimentare={handleModAlimentare}
                    dataSolicitata={dataSolicitata}
                    handleDataSolicitata={handleDataSolicitata}
                    dateTehnice={dateTehnice}
                    handleDateTehnice={handleDateTehnice}
                    tipTarif={tipTarif}
                    handleTipTarif={handleTipTarif}
                    tensiune={tensiune}
                    tipTensiune={tipTensiune}
                    handleTipTensiune={handleTipTensiune}
                    handleTensiune={handleTensiune}
                    factorPutere={factorPutere}
                    handleFactorPutere={handleFactorPutere}
                    tipFactorPutere={tipFactorPutere}
                    handleTipFactorPutere={handleTipFactorPutere}
                    specificulActivitatii={specificulActivitatii}
                    handleSpecificulActivitatii={handleSpecificulActivitatii}
                    otherSA={otherSA}
                    handleOtherSA={handleOtherSA}
                    regimLucru={regimLucru}
                    handleRegimLucru={handleRegimLucru}
                    surseDeAlimentare={surseDeAlimentare}
                    handleSurseDeAlimentare={handleSurseDeAlimentare}
                    timpIntrerupere={timpIntrerupere}
                    handleTimpIntrerupere={handleTimpIntrerupere}
                    listaReceptoare={listaReceptoare}
                    SI={SI}
                    handleSI={handleSI}
                    statiiIncarcare={statiiIncarcare}
                    totalPI={totalPI}
                    handleTotalPIUser={handleTotalPIUser}
                    handleDateLR={handleDateLR}
                    handleDateSI={handleDateSI}
                    dateLR={dateLR}
                    dateSI={dateSI}
                />

            }

            { ((currentStep === 4 && startingModalOption === 2) || (currentStep === 3 && startingModalOption === 1)) && renderLocProducere() }

            { ((currentStep === 5 && startingModalOption === 2) || (currentStep === 4 && startingModalOption === 1)) &&
                <DocumenteAnexate
                    isEditing={isEditing}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                    progress={progress}
                    setProgress={handleProgress}
                    files={files}
                    handleFiles={handleSetFiles}
                    wereFilesMounted={wereFilesMounted}
                    handleWereFilesMounted={handleWereFilesMounted}
                    uploadedFiles={uploadedFiles}
                    handleInitalFilesToUpload={handleInitalFilesToUpload}
                    uploadFile={uploadFile}
                    alreadyUploaded={alreadyUploaded}
                    handleShowExtraFiles={handleShowExtraFiles}
                    reloadAlreadyUploadedDocuments={reloadAlreadyUploadedDocuments}
                />
            }
            { ((currentStep === 6 && startingModalOption === 2) || (currentStep === 5 && startingModalOption === 1)) &&
                <InformareFinala
                    isEditing={isEditing}
                    checkedOne={checkedOne}
                    checkedTwo={checkedTwo}
                    handleCheckOne={handleCheckedOne}
                    handleCheckTwo={handleCheckedTwo}
                    requestId={requestId}
                    setStep={handleStepChange}
                    currentStep={currentStep}
                />
            }
        </Fragment>;
    };

    return (
        <div className="d-flex justify-content-center flex-column">
            { !noRequest ? (showStartingModal ? renderModal() : (!isFinalized || !isEditing) && renderSteps()) : <p className="red d-flex justify-content-center fw-bold">A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.</p>}
            { (isFinalized && isEditing) && <Fragment>
                <p className="red d-flex justify-content-center fw-bold mb-3">Cererea a fost deja finalizată.</p> 
                <Button style={{margin: '0 auto'}} onClick={() => window.location.href = `${OLD_SITE_URL}/lista-cereri-racordare`}>Listă cereri racordare</Button>
            </Fragment>}
        </div>
    );
};

export default CerereProsumatori;