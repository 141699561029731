import React from 'react';
import { DataTypes, DetailActionResponse } from '@type/backOfficeModule';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { DownloadFile } from '@api/backOffice/fetchResults';
import Base64FileListReadOnly from './Fields/Base64FileListReadOnly';
import FrontEndDataViewer from './Fields/FrontEndDataViewer';
import * as notification from '@lib/Notification';

function useBoHelpers() {
  const dispatch = useDispatch();

  const getDetailsValueForItemByType = (item: any, detailObject: DetailActionResponse) => {
    let value: any = '';

    const keyFieldName: string = item?.name?.charAt(0).toLowerCase() + item?.name?.slice(1);
    switch (item.dataTypeId) {
      case DataTypes.ReadonlyString:
        value = <span>{detailObject[keyFieldName]}</span>;
        break;
      case DataTypes.ReadonlyDate:
        value = <span>{detailObject[keyFieldName] ? moment.utc(detailObject[keyFieldName])?.local()?.format('DD.MM.YYYY') : '-'}</span>;
        break;
      case DataTypes.ReadonlyDateTime:
        value = (
          <span>{detailObject[keyFieldName] ? moment.utc(detailObject[keyFieldName])?.local()?.format('DD.MM.YYYY HH:mm') : '-'}</span>
        );
        break;
      case DataTypes.UrlList:
        if (Array.isArray(detailObject[keyFieldName])) {
          value = detailObject[keyFieldName].map((item: { title: string; url: string }, index: number) => (
            <div key={index}>
              <a href={item.url}>{item.title}</a>
            </div>
          ));
        }
        if (Array.isArray(value) && value.length == 0) {
          value = null;
        }
        break;
      case DataTypes.Date:
        value = <span>{detailObject[keyFieldName] ? moment.utc(detailObject[keyFieldName])?.local()?.format('DD.MM.YYYY') : '-'}</span>;
        break;
      case DataTypes.HTML:
        value = <div dangerouslySetInnerHTML={{ __html: detailObject[keyFieldName] }} />;
        break;
      case DataTypes.Url:
        if (!detailObject[keyFieldName]) return;
        if (detailObject[keyFieldName]?.isInternal) {
          value = (
            <a
              href="#!"
              onClick={async () => {
                dispatch(toggleLoader(true));
                let res = '' as BlobPart;
                let isError = false;
                res = await DownloadFile(detailObject[keyFieldName]?.url, true).catch((error) => {
                  isError = true;
                  dispatch(toggleLoader(false));
                  try {
                    const decoder = new TextDecoder('utf-8');
                    const decodedString = decoder.decode(error);
                    const obj = JSON.parse(decodedString);
                    const status = obj.status;
                    if (status == 401) {
                      notification.error('Sesiunea a expirat. Pagina se va reâncărca automat');
                      setTimeout(() => {
                        window.location.reload();
                      }, 5000);
                    } else {
                      notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                    }
                  } catch (error) {
                    notification.error('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                  }
                });
                if (!isError) {
                  try {
                    setTimeout(() => {
                      const blob: Blob = new Blob([res]);
                      const fileName: string = detailObject[keyFieldName]?.fileName
                        ? `${detailObject[keyFieldName]?.fileName}-${moment().format('YYMMDDHms')}${detailObject[keyFieldName]?.extension}`
                        : `Fisier-${moment().format('YYMMDDHms')}.pdf`;
                      const objectUrl: string = URL.createObjectURL(blob);
                      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

                      a.href = objectUrl;
                      a.download = fileName;
                      document.body.appendChild(a);
                      a.click();

                      document.body.removeChild(a);
                      URL.revokeObjectURL(objectUrl);
                      dispatch(toggleLoader(false));
                    }, 500);
                  } catch (error) {
                    dispatch(toggleLoader(false));
                  }
                }
              }}
            >
              {detailObject[keyFieldName]?.title}
            </a>
          );
        } else {
          value = <a href={detailObject[keyFieldName]?.url}>{detailObject[keyFieldName]?.title}</a>;
        }
        break;
      case DataTypes.Bool:
        value = detailObject[keyFieldName] ? 'Da' : 'Nu';
        break;
      case DataTypes.Base64FileList:
        value = (
          <Base64FileListReadOnly
            label={''}
            //@ts-ignore
            viewFilesValues={column.value.map((item: any) => {
              return {
                blobName: item.fileName,
                originalFileName: item.fileName,
                documentUrl: item.fileDownloadUrl
              };
            })}
          />
        );
        break;
      case DataTypes.StringList:
        value = '';
        if (detailObject[keyFieldName] && detailObject[keyFieldName].length > 0) {
          if (detailObject[keyFieldName].length == 1) {
            value = detailObject[keyFieldName][0];
          } else {
            value = detailObject[keyFieldName].map((item: string, index: number) => (
              <p key={index} className="m-0">
                {item};
              </p>
            ));
          }
        }
        break;
      case DataTypes.FrontEndDataViewer:
        if (detailObject[keyFieldName]) {
          value = (
            <FrontEndDataViewer
              footer={detailObject[keyFieldName].footer}
              header={detailObject[keyFieldName].header}
              tableGridStructure={detailObject[keyFieldName].tableGridStructure}
            />
          );
        }
        break;
      default:
        value = detailObject[keyFieldName];
        break;
    }

    return value ? value : item.hideIfEmptyValue ? '' : item.messageForEmptyValue ? item.messageForEmptyValue : '-';
  };

  return {
    getDetailsValueForItemByType
  };
}

export default useBoHelpers;
