import { GetCounties, GetLocalities } from '@api/geoApi';
import DatePicker from '@components/common/Date/DatePicker';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ImputernicitPFSchema } from '../../../helpers/schemas';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const ImputernicitPF = (props: { isEditing: any, innerRef: any, submit: any, formData: any, handleFormData: any, isEmailDisabled: boolean }) => {
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    //eslint-disable-next-line
    const [locality, setLocality] = useState<number | null>(null);

    //eslint-disable-next-line
    const { isEditing, innerRef, submit, formData, handleFormData, isEmailDisabled } = props;

    useEffect(() => {
        GetCounties().then(res => setCounties(res));

        if (formData.Judet !== '') {
            GetLocalities(formData.Judet).then((res) => {
                setLocalities(res);
                res.map((c: LocalityTemplate, i: number) => {
                    if (formData.Localitate === c.localityId) {
                        console.log(c);
                        setLocality(c.localityId);
                    }
                });
            });
        }

    }, []);

    useEffect(() => {
        county !== '' && GetLocalities(county).then((res) => setLocalities(res));
    }, [county]);

    return (
        <div>
            <Formik
                initialValues={formData}
                innerRef={innerRef}
                validationSchema={ImputernicitPFSchema}
                validateOnMount={true}
                validateOnChange={true}
                onSubmit={(values) => (values)}
            >
                {(props) => (
                    <Form>
                        <Row>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Field label='Nume' id="Nume" name="Nume" placeholder="Nume">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.Nume}
                                            label='Nume*'
                                            onBlur={() => props.setFieldTouched('Nume')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Nume');
                                                props.setFieldValue('Nume', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Nume" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Field label='Prenume' id="Prenume" name="Prenume" placeholder="Prenume">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.Prenume}
                                            label='Prenume*'
                                            onBlur={() => props.setFieldTouched('Prenume')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Prenume');
                                                props.setFieldValue('Prenume', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Prenume" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Field label='CNP' id="CNP" name="CNP" placeholder="Cod Numeric Personal (CNP)">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.CNP}
                                            label='Cod Numeric Personal (CNP)*'
                                            onBlur={() => props.setFieldTouched('CNP')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'CNP');
                                                props.setFieldValue('CNP', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="CNP" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={2}>
                                <Field label='SerieCI' id="SerieCI" name="SerieCI" placeholder="Serie act de identitate*">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.SerieCI}
                                            label='Serie act de identitate*'
                                            onBlur={() => props.setFieldTouched('SerieCI')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'SerieCI');
                                                props.setFieldValue('SerieCI', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="SerieCI" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" xs={12} sm={2}>
                                <Field label='NumarCI' id="NumarCI" name="NumarCI" placeholder="Număr act de identitate*">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.NumarCI}
                                            label='Număr act de identitate*'
                                            onBlur={() => props.setFieldTouched('NumarCI')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'NumarCI');
                                                props.setFieldValue('NumarCI', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="NumarCI" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" xs={12} sm={2}>
                                <Field label='CIData' id="CIData" name="CIData" placeholder="Dată eliberare act de identitate*">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <DatePicker
                                            label="Dată eliberare act de identitate*"
                                            name="Dată eliberare act de identitate*"
                                            selected={formData.CIData !== '' ? moment(formData.CIData).toDate() : formData.CIData}
                                            onChange={(e: any) => {
                                                if (e !== null) {
                                                    handleFormData(e, 'CIData');
                                                    props.setFieldValue('CIData', e);
                                                } else {
                                                    handleFormData('', 'CIData');
                                                    props.setFieldValue('CIData', '');
                                                }
                                            }}
                                            disabled={isEditing === false}
                                            maxDate={moment().toDate()}
                                            mbZero
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="CIData" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" xs={12} sm={2}>
                                <Field label='CIDataExp' id="CIDataExp" name="CIDataExp" placeholder="Dată expirare act de identitate*">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <DatePicker 
                                            label="Dată expirare act de identitate*" 
                                            name="Dată expirare act de identitate*" 
                                            selected={formData.CIDataExp !== '' ? moment(formData.CIDataExp).toDate() : formData.CIDataExp}
                                            onChange={(e: any) => {
                                                if (e !== null) {
                                                    handleFormData(e, 'CIDataExp');
                                                    props.setFieldValue('CIDataExp', e);
                                                } else {
                                                    handleFormData('', 'CIDataExp');
                                                    props.setFieldValue('CIDataExp', '');
                                                }
                                            }}
                                            disabled={isEditing === false}
                                            minDate={moment().add('1', 'day').toDate()}
                                            mbZero
                                            // dateFormat={'YYYY-MM-DDThh:mm:ss'}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="CIDataExp" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Field label='CIEliberatDe' id="CIEliberatDe" name="CIEliberatDe" placeholder="Data eliberare act de identitate*">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.CIEliberatDe}
                                            label='Act de identitate eliberat de*'
                                            onBlur={() => props.setFieldTouched('CIEliberatDe')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'CIEliberatDe');
                                                props.setFieldValue('CIEliberatDe', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="CIEliberatDe" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={6}>
                                <Field label='Telefon' id="Telefon" name="Telefon" placeholder="Telefon">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.Telefon}
                                            label='Telefon*'
                                            onBlur={() => props.setFieldTouched('Telefon')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Telefon');
                                                props.setFieldValue('Telefon', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Telefon" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" xs={12} sm={6}>
                                <Field label='Email' id="Email" name="Email" placeholder="Email">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.Email}
                                            label='E-mail*'
                                            onBlur={() => props.setFieldTouched('Email')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Email');
                                                props.setFieldValue('Email', e.target.value);
                                            }}
                                            disabled={isEditing === false || isEmailDisabled}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Email" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Dropdown
                                    options={counties.map((option, index) => (
                                        {
                                            id: option.countyCode,
                                            name: option.countyName
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Județ*'}
                                    defaultPlaceholder={'Alege județul'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        value && handleFormData(value, 'Judet');
                                        value && props.setFieldValue('Județ', value);
                                        value.length === 0 && props.setFieldValue('Județ', '');
                                        setCounty(value);
                                    }}
                                    value={formData.Judet}
                                    mbZero
                                    disabled={isEditing === false}
                                />
                                <ErrorMessage name="Judet" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Dropdown
                                    options={localities.map((option, index) => (
                                        {
                                            id: option.localityCode,
                                            name: option.localityName
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Localitate*'}
                                    defaultPlaceholder={'Alege localitatea'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        value && handleFormData(value, 'Localitate');
                                        value && props.setFieldValue('Localitate', value);
                                        value.length === 0 && props.setFieldValue('Localitate', '');
                                    }}
                                    value={formData.Localitate}
                                    mbZero
                                    disabled={isEditing === false}
                                />
                                <ErrorMessage name="Localitate" render={msg => <div style={{ color: '#ea1c0a', fontWeight: 'bold' }}>{msg}</div>} />
                            </Col>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Field label='Comuna' id="Comuna" name="Comuna" placeholder="Comuna">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Comuna}
                                            label='Comună'
                                            onBlur={() => props.setFieldTouched('Comuna')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Comuna');
                                                props.setFieldValue('Comuna', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Strada' id="Strada" name="Strada" placeholder="Strada">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Strada}
                                            label='Stradă'
                                            onBlur={() => props.setFieldTouched('Strada')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Strada');
                                                props.setFieldValue('Strada', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Numar' id="Numar" name="Numar" placeholder="Numar">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Numar}
                                            label='Numar'
                                            onBlur={() => props.setFieldTouched('Numar')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Numar');
                                                props.setFieldValue('Numar', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Bloc' id="Bloc" name="Bloc" placeholder="Bloc">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Bloc}
                                            label='Bloc'
                                            onBlur={() => props.setFieldTouched('Bloc')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Bloc');
                                                props.setFieldValue('Bloc', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Scara' id="Scara" name="Scara" placeholder="Scara">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Scara}
                                            label='Scara'
                                            onBlur={() => props.setFieldTouched('Scara')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Scara');
                                                props.setFieldValue('Scara', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Etaj' id="Etaj" name="Etaj" placeholder="Etaj">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.Etaj}
                                            label='Etaj'
                                            onBlur={() => props.setFieldTouched('Etaj')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Etaj');
                                                props.setFieldValue('Etaj', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Ap' id="Ap" name="Ap" placeholder="Ap">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Ap}
                                            label='Apartament'
                                            onBlur={() => props.setFieldTouched('Ap')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Ap');
                                                props.setFieldValue('Ap', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3">
                                <Field label='NrAutorizare' id="NrAutorizare" name="NrAutorizare" placeholder="NrAutorizare">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.NrAutorizare}
                                            label='Număr act autorizare și data emiterii'
                                            onBlur={() => props.setFieldTouched('NrAutorizare')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'NrAutorizare');
                                                props.setFieldValue('NrAutorizare', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3">
                                <Field label='ActEmisDe' id="ActEmisDe" name="ActEmisDe" placeholder="ActEmisDe">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.ActEmisDe}
                                            label='Act emis de'
                                            onBlur={() => props.setFieldTouched('ActEmisDe')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'ActEmisDe');
                                                props.setFieldValue('ActEmisDe', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ImputernicitPF;