import { GetCounties, GetLocalities } from '@api/geoApi';
import { Dropdown, DropdownOptionType } from '@components/common/Dropdown';
import { Input } from '@components/common/Input';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DateIdentificarePJOptEmailSchema } from '../../../helpers/schemas';

type CountyTemplate = {
    countyId: number,
    countyName: string,
    countyCode: string
};

type LocalityTemplate = {
    localityId: number,
    localityName: string,
    localityCode: string
};

const DateDeIdentificarePJOptEmail = (props: { isEditing: any, innerRef: any, formData: any, handleFormData: any}) => {
    const [counties, setCounties] = useState<CountyTemplate[]>([]);
    const [county, setCounty] = useState('');
    const [localities, setLocalities] = useState<LocalityTemplate[]>([]);
    //eslint-disable-next-line
    const [locality, setLocality] = useState<number | null>(null);

    const {isEditing, innerRef, formData, handleFormData} = props;

    useEffect(() => {
        GetCounties().then(res => setCounties(res));

        if (formData.Judet !== '') {
            GetLocalities(formData.Judet).then((res) => {
                setLocalities(res);
                res.map((c: LocalityTemplate, i: number) => {
                    if (formData.Localitate === c.localityId) {
                        setLocality(c.localityId);
                    }
                });
            });
        }
    }, []);

    useEffect(() => {
        county !== '' && GetLocalities(county).then((res) => setLocalities(res));
    }, [county]);

    return (
        <div>
            <Formik
                initialValues={formData}
                enableReinitialize={true}
                onSubmit={(values) => (values)}
                validationSchema={DateIdentificarePJOptEmailSchema}
                innerRef={innerRef}
                validateOnMount={true}
            >
                {(props) => (
                    <Form>
                        <Row>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Field label='Denumire' id="Denumire" name="Denumire" placeholder="Denumire">
                                    {({
                                          //@ts-ignore
                                          meta: { touched, error }
                                      }) =>
                                        <Input
                                            mbZero
                                            value={formData.Denumire}
                                            label='Denumire societate*'
                                            onBlur={() => props.setFieldTouched('Denumire')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Denumire');
                                                props.setFieldValue('Denumire', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Denumire" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Field label='CUI' id="CUI" name="CUI" placeholder="CUI">
                                    {({
                                          //@ts-ignore
                                          meta: { touched, error }
                                      }) =>
                                        <Input
                                            mbZero
                                            value={formData.CUI}
                                            label='CUI (cod de înregistrare fiscală)*'
                                            onBlur={() => props.setFieldTouched('CUI')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'CUI');
                                                props.setFieldValue('CUI', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="CUI" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Field label='NrReg' id="NrReg" name="NrReg" placeholder="Nr. de înregistrare la Registrul comerțului (J)*">
                                    {({
                                          //@ts-ignore
                                          meta: { touched, error }
                                      }) =>
                                        <Input
                                            mbZero
                                            value={formData.NrReg}
                                            label='Nr. de înregistrare la Registrul comerțului (J)*'
                                            onBlur={() => props.setFieldTouched('NrReg')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'NrReg');
                                                props.setFieldValue('NrReg', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="NrReg" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={6}>
                                <Field label='Telefon' id="Telefon" name="Telefon" placeholder="Telefon">
                                    {({
                                          //@ts-ignore
                                          meta: { touched, error }
                                      }) =>
                                        <Input
                                            mbZero
                                            value={formData.Telefon}
                                            label='Telefon'
                                            onBlur={() => props.setFieldTouched('Telefon')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Telefon');
                                                props.setFieldValue('Telefon', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Telefon" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={6}>
                                <Field label='Email' id="Email" name="Email" placeholder="Email">
                                    {({
                                          //@ts-ignore
                                          meta: { touched, error }
                                      }) =>
                                        <Input
                                            mbZero
                                            value={formData.Email}
                                            label='Email'
                                            onBlur={() => props.setFieldTouched('Email')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Email');
                                                props.setFieldValue('Email', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Email" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={12} sm={6} md={3}>
                                <Field label='Cont' id="Cont" name="Cont" placeholder="Cont">
                                    {({
                                          //@ts-ignore
                                          meta: { touched, error }
                                      }) =>
                                        <Input
                                            mbZero
                                            value={formData.Cont}
                                            label='Cont bancar'
                                            onBlur={() => props.setFieldTouched('Cont')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Cont');
                                                props.setFieldValue('Cont', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Cont" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={6} md={3}>
                                <Field label='Banca' id="Banca" name="Banca" placeholder="Banca">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Banca}
                                            label='Deschis la Banca'
                                            onBlur={() => props.setFieldTouched('Banca')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Banca');
                                                props.setFieldValue('Banca', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={12} sm={6} md={3}>
                                <Field label='Reprezentant' id="Reprezentant" name="Reprezentant" placeholder="Reprezentant">
                                    {({
                                          //@ts-ignore
                                          meta: { touched, error }
                                      }) =>
                                        <Input
                                            mbZero
                                            value={formData.Reprezentant}
                                            label='Reprezentant legal al firmei*'
                                            onBlur={() => props.setFieldTouched('Reprezentant')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Reprezentant');
                                                props.setFieldValue('Reprezentant', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="Reprezentant" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={6} md={3}>
                                <Field label='InCalitateDe' id="InCalitateDe" name="InCalitateDe" placeholder="InCalitateDe">
                                    {({
                                          //@ts-ignore
                                          meta: { touched, error }
                                      }) =>
                                        <Input
                                            mbZero
                                            value={formData.InCalitateDe}
                                            label='In calitate de*'
                                            onBlur={() => props.setFieldTouched('InCalitateDe')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'InCalitateDe');
                                                props.setFieldValue('InCalitateDe', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                                <ErrorMessage name="InCalitateDe" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                        </Row>
                        <br></br>
                        <h5>Adresa sediului social al solicitantului (clientul final)</h5>
                        <br></br>
                        <Row>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Dropdown
                                    options={counties.map((option, index) => (
                                        {
                                            id: option.countyCode,
                                            name: option.countyName
                                        }
                                    )) as DropdownOptionType[]}
                                    label={'Județ*'}
                                    defaultPlaceholder={'Alege județul'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        value && props.setFieldValue('Judet', value);
                                        value && handleFormData(value, 'Judet');
                                        value.length === 0 && props.setFieldValue('Judet', '');
                                        setCounty(value);
                                    }}
                                    value={formData.Judet}
                                    mbZero
                                    disabled={isEditing === false}
                                />
                                <ErrorMessage name="Judet" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Dropdown
                                    options={localities.map((option, index) => (
                                        {
                                            id: option.localityCode,
                                            name: option.localityName
                                        }
                                    )) as  DropdownOptionType[]}
                                    label={'Localitate*'}
                                    defaultPlaceholder={'Alege localitatea'}
                                    displaySearch={true}
                                    onChange={(value) => {
                                        value && handleFormData(value, 'Localitate');
                                        value && props.setFieldValue('Localitate', value);
                                        value.length === 0 && props.setFieldValue('Localitate', '');
                                    }}
                                    value={formData.Localitate}
                                    mbZero
                                    disabled={isEditing === false}
                                />
                                <ErrorMessage name="Localitate" render={msg => <div style={{color: '#ea1c0a', fontWeight: 'bold'}}>{msg}</div>}/>
                            </Col>
                            <Col className="mb-3" xs={12} sm={4}>
                                <Field label='Comuna' id="Comuna" name="Comuna" placeholder="Comuna">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Comuna}
                                            label='Comună'
                                            onBlur={() => props.setFieldTouched('Comuna')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Comuna');
                                                props.setFieldValue('Comuna', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Strada' id="Strada" name="Strada" placeholder="Strada">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Strada}
                                            label='Stradă'
                                            onBlur={() => props.setFieldTouched('Strada')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Strada');
                                                props.setFieldValue('Strada', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Numar' id="Numar" name="Numar" placeholder="Numar">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Numar}
                                            label='Număr'
                                            onBlur={() => props.setFieldTouched('Numar')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Numar');
                                                props.setFieldValue('Numar', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Bloc' id="Bloc" name="Bloc" placeholder="Bloc">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Bloc}
                                            label='Bloc'
                                            onBlur={() => props.setFieldTouched('Bloc')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Bloc');
                                                props.setFieldValue('Bloc', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Scara' id="Scara" name="Scara" placeholder="Scara">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Scara}
                                            label='Scară'
                                            onBlur={() => props.setFieldTouched('Scara')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Scara');
                                                props.setFieldValue('Scara', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Etaj' id="Etaj" name="Etaj" placeholder="Etaj">
                                    {({
                                        //@ts-ignore
                                        meta: { touched, error }
                                    }) =>
                                        <Input
                                            mbZero
                                            value={formData.Etaj}
                                            label='Etaj'
                                            onBlur={() => props.setFieldTouched('Etaj')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Etaj');
                                                props.setFieldValue('Etaj', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                            className={touched && error ? 'forced-focus' : ''}
                                        />
                                    }
                                </Field>
                            </Col>
                            <Col className="mb-3" xs={6} sm={2}>
                                <Field label='Ap' id="Ap" name="Ap" placeholder="Ap">
                                    {() =>
                                        <Input
                                            mbZero
                                            value={formData.Ap}
                                            label='Apartament'
                                            onBlur={() => props.setFieldTouched('Ap')}
                                            onChange={(e: any) => {
                                                handleFormData(e.target.value, 'Ap');
                                                props.setFieldValue('Ap', e.target.value);
                                            }}
                                            disabled={isEditing === false}
                                        />
                                    }
                                </Field>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
            <p>Te rugăm să verifici că datele de identificare, inclusiv număr de telefon și adresa de e-mail, sunt corecte. Corectitudinea datelor este importantă pentru a avea o comunicare eficientă pe parcursul procesului.</p>
        </div>
    );
};

export default DateDeIdentificarePJOptEmail;