import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { Select } from '@components/common/Select';
import ChoiceComponent from '@components/prosumatori/components/ChoiceComponent';
import DateDeIdentificarePF from '../forms/DateDeIdentificarePF';
import DateDeIdentificarePJ from '../forms/DateDeIdentificarePJ';
import { useDispatch, useSelector } from 'react-redux';
import { toggleLoader } from '@actions/LoaderActions';
import { getTypes } from '@api/prosumatori/prosumatori';
import { postStepOne } from '@api/iConnect/iConnectGaz';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Tippy from '@tippyjs/react';
import Adress from '../forms/Adress';
import { IState } from '@type/store';
import {jwtDecode} from 'jwt-decode';
import DateDeIdentificarePFOptEmail from '../forms/DateIdentificarePFOptEmail';
import DateDeIdentificarePJOptEmail from '../forms/DateIdentificarePJOptEmail';

type JWT = {
    aud: string;
    aut: string;
    azp: string;
    deviceid: string;
    exp: number;
    given_name: string;
    groups: string[];
    iat: number;
    iss: string;
    jti: string;
    nbf: number;
    scope: string;
    sub: string;
    userid: string;
}

const ClientFinal = (props: {
    isEditing: any,
    requestId: any,
    handleRequestId: any,
    startingModalOption: any,
    handleFormData: any,
    formData: any,
    setStep: any,
    currentStep: any,
    progress: any,
    setProgress: any,
    clientType: any,
    handleClientType: any,
    newClient: any,
    handleNewClient: any,
    dezvoltator: any,
    handleDezvoltator: any,
    adressType: any,
    handleAdressType: any,
    adressData: any,
    handleAdressData: any,
    type: any,
    handleType: any,
    group: any,
    handleGroup: any
}) => {
    const {
        isEditing,
        requestId,
        handleRequestId,
        startingModalOption,
        handleFormData,
        formData,
        setStep,
        currentStep,
        progress,
        setProgress,
        clientType,
        handleClientType,
        newClient,
        handleNewClient,
        dezvoltator,
        handleDezvoltator,
        adressType,
        handleAdressType,
        adressData,
        handleAdressData,
        type,
        handleType,
        group,
        handleGroup
    } = props;

    //eslint-disable-next-line
    const [applicantTypes, setApplicantTypes] = useState([]);
    const [clientTypes, setClientTypes] = useState([]);
    const [clientTypesErr, setClientTypesErr] = useState('');
    const [clientTypeError, setClientTypeError] = useState('');
    const [groupError, setGroupError] = useState('');
    const [newClientError, setNewClientError] = useState('');
    const [dezvoltatorError, setDezvoltatorError] = useState('');
    const [adressTypeError, setAdressTypeError] = useState('');
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);

    const dateIdentifPFRef = useRef(null);
    const dateIdentifPJRef = useRef(null);
    const adressRef = useRef(null);

    const dispatch = useDispatch();

    const token: string | undefined = useSelector<IState>((state) => state?.auth?.token) as string;
    let jwt: JWT = jwtDecode(token);

    useEffect(() => {
        window.scrollTo(0, 0);

        getOptionTypes(['ApplicantType']).then((res) => {
            setApplicantTypes(res);
        });

        getOptionTypes(['ClientType']).then((res) => {
            setClientTypes(res);
        });

        startingModalOption === 1 && jwt.groups.includes('ClientFizic') && handleClientType('PF');
        startingModalOption === 1 && jwt.groups.includes('ClientJuridic') && handleClientType('PJ');
    }, []);

    const getOptionTypes = async (keys: string[]) => {
        return await getTypes(keys, 'GN').then((res: any) => {
            return res[keys[0]];
        });
    };

    const constructPayload = () => {
        const adresa = {
            Judet: formData.Judet,
            Localitate: formData.Localitate,
            Comuna: formData.Comuna,
            Strada: formData.Strada,
            Numar: formData.Numar,
            Bloc: formData.Bloc,
            Etaj: formData.Etaj,
            Scara: formData.Scara,
            Ap: formData.Ap
        };

        let adr;
        if (startingModalOption === 2) {
            adr = adresa;
        } else {
            if (adressType === 'AU') {
                adr = adresa;
            } else {
                adr = adressData;
            }
        }

        if (clientType === '2' || clientType === 'PJ') {
            formData.CIData = null;
        }

        const payload = {
            TipSolicitant: clientType,
            DateIdentificare: {
                ...formData,
                Adresa: adresa
            },
            AdresaCorespondenta: adr,
            ...((requestId !== null && requestId !== -1) && { requestId: requestId }),
            TipCorespondenta: adressType,
            reprezentant: startingModalOption !== 1,
            ClientNou: newClient,
            Dezvoltator: dezvoltator,
            tip_client: type,
            grup_persoane: group
        };

        ['Judet', 'Localitate', 'Comuna', 'Strada', 'Numar', 'Bloc', 'Etaj', 'Scara', 'Ap'].forEach(e => delete payload.DateIdentificare[e]);

        return payload;
    };

    const stepOneApiCall = async (payload: any) => {
        dispatch(toggleLoader(true));

        await postStepOne(payload)
            .then((response: any) => {
                if (response) {
                    if (requestId === null || requestId === -1) {
                        handleRequestId(response);
                    }
                    setShowModal(true);
                    // setStep(currentStep + 1);
                    // currentStep === progress && setProgress(currentStep + 1);
                }
            })
            .catch((error: any) => {
                if (error && error.status === 200) {
                    setShowModal(true);
                    // setStep(currentStep + 1);
                    // currentStep === progress && setProgress(currentStep + 1);
                } else if (error === 'Request is completed') {
                    setError('Cererea a fost deja finalizată.');
                } else if (error && error.StatusCode && error.StatusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                } else if (error && error.statusCode && error.statusCode === 500) {
                    setError('A intervenit o eroare tehnică. Vă rugăm reveniți mai târziu.');
                }
            });

        dispatch(toggleLoader(false));
    };

    const handleNextStep = () => {
        if (isEditing !== false) {
            let ac = document.getElementById('ac');
            ac?.classList.remove('red');
            setClientTypeError('');
            setAdressTypeError('');
            setError('');
            setNewClientError('');
            setClientTypesErr('');
            setGroupError('');
            setDezvoltatorError('');
            let client = document.getElementById('client');
            client?.classList.remove('red');
            let tip = document.getElementById('tip');
            tip?.classList.remove('forced-focus');
            let grup = document.getElementById('grup');
            grup?.classList.remove('red');
            let dev = document.getElementById('dev');
            dev?.classList.remove('red');

            if (clientType === '') {
                setClientTypeError('Te rugăm să alegi tipul de client.');
            }

            if (clientType !== '' && newClient === null) {
                setNewClientError('Te rugăm să alegi o opțiune.');
                let err = document.getElementById('client');
                err?.classList.add('red');
            }

            if (clientType !== '' && (type === 0 || type === -1)) {
                setClientTypesErr('Te rugăm să alegi o opțiune.');
                let err = document.getElementById('tip');
                err?.classList.add('forced-focus');
            }

            if (clientType !== '' && group === null) {
                setGroupError('Te rugăm să alegi o opțiune.');
                let err = document.getElementById('grup');
                err?.classList.add('red');
            }

            if (clientType !== '' && dezvoltator === null) {
                setDezvoltatorError('Te rugăm să alegi o opțiune.');
                let err = document.getElementById('dev');
                err?.classList.add('red');
            }

            //@ts-ignore
            dateIdentifPFRef.current?.submitForm();
            //@ts-ignore
            dateIdentifPJRef.current?.submitForm();

            if (startingModalOption === 1 && clientType !== '') {
                if (adressType === '') {
                    setAdressTypeError('Te rugăm să alegi adresa de corespondență.');
                    let err = document.getElementById('ac');
                    err?.classList.add('red');
                } else {
                    //@ts-ignore
                    adressRef.current?.submitForm();
                }
            }

            if (newClient !== null && dezvoltator !== null && (type !== 0 && type !== -1)) {
                //@ts-ignore
                if (dateIdentifPFRef.current && dateIdentifPFRef.current?.isValid === true) {
                    if (startingModalOption === 2) {
                        stepOneApiCall(constructPayload());
                    } else {
                        //@ts-ignore
                        if (adressRef.current && adressRef.current?.isValid === true) {
                            stepOneApiCall(constructPayload());
                        } else if (!adressRef.current) {
                            // call endpoint
                            stepOneApiCall(constructPayload());
                        }
                    }
                    //@ts-ignore
                } else if (dateIdentifPJRef.current && dateIdentifPJRef.current?.isValid === true) {
                    if (startingModalOption === 2) {
                        stepOneApiCall(constructPayload());
                    } else {
                        //@ts-ignore
                        if (adressRef.current && adressRef.current?.isValid === true) {
                            stepOneApiCall(constructPayload());
                        } else if (!adressRef.current) {
                            stepOneApiCall(constructPayload());
                        }
                    }
                }
            }
        } else {
            setStep(currentStep + 1);
            currentStep === progress && setProgress(currentStep + 1);
        }
    };

    const renderModal = () => {
        return <Modal
            show={showModal}
            centered
            onHide={() => {
                setShowModal(false);
                // setStep(currentStep + 1);
                // currentStep === progress && setProgress(currentStep + 1);
            }}
            onClose={() => {
                setShowModal(false);
                setStep(currentStep + 1);
                currentStep === progress && setProgress(currentStep + 1);
            }}
        >
            <Modal.Header closeButton>
                <span style={{ height: '30px' }}></span>
            </Modal.Header>
            <Modal.Body>
                <p>Prin apăsarea butonului <b>Pasul următor</b> se salvează informațiile completate până acum. Poți reveni oricând în pasul salvat, pentru editare, sau poți continua cererea din secțiunea <b>Listă cereri racordare</b> din meniul din stânga.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={() => {
                        setStep(currentStep + 1);
                        currentStep === progress && setProgress(currentStep + 1);
                    }
                    }
                >
                    OK
                </Button>
            </Modal.Footer>
        </Modal>;
    };

    useEffect(() => {
        console.log('(1) PF logat / PF slectat / Fara reprezentant: ', clientType === 'PF' && jwt.groups.includes('ClientFizic') && startingModalOption === 1);
        console.log('(2) PF logat / PF slectat / Cu reprezentant: ', clientType === 'PF' && jwt.groups.includes('ClientFizic') && startingModalOption === 2);
        console.log('(3) PF logat / PJ slectat / Fara reprezentant: ', clientType === 'PJ' && jwt.groups.includes('ClientFizic') && startingModalOption === 1);
        console.log('(4) PF logat / PJ slectat / Cu reprezentant: ', clientType === 'PJ' && jwt.groups.includes('ClientFizic') && startingModalOption === 2);

        console.log('(5) PJ logat / PF slectat / Fara reprezentant: ', clientType === 'PF' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1);
        console.log('(6) PJ logat / PF slectat / Cu reprezentant: ', clientType === 'PF' && jwt.groups.includes('ClientJuridic') && startingModalOption === 2);
        console.log('(7) PJ logat / PJ slectat / Fara reprezentant: ', clientType === 'PJ' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1);
        console.log('(8) PJ logat / PJ slectat / Cu reprezentant: ', clientType === 'PJ' && jwt.groups.includes('ClientJuridic') && startingModalOption === 2);
    }, [clientType]);

    return (
        <div className="iconnect-client-final m-3 w-100 d-flex align-self-center flex-column">
            <Row>
                <h4 className="register-section-title mt-3">Date de identificare ale solicitantului (clientul final)</h4>
                <Col xs={12} sm={6}>
                    <Select
                        onChange={(e: any) => {
                            setClientTypeError('');
                            if (e.target.value === 'PF' && jwt.groups.includes('ClientJuridic')) handleFormData('', 'Email');
                            if (e.target.value === 'PJ' && jwt.groups.includes('ClientFizic')) handleFormData('', 'Email');
                            if (e.target.value === 'PF' && jwt.groups.includes('ClientFizic') && startingModalOption === 1) handleFormData(jwt.sub, 'Email');
                            if (e.target.value === 'PJ' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1) handleFormData(jwt.sub, 'Email');
                            handleClientType(e.target.value);
                        }}
                        options={[{ name: 'Persoană fizică', id: 'PF' }, { name: 'Persoană juridică', id: 'PJ' }]}
                        placeholder={'Alege tipul de client'}
                        value={clientType}
                        mbZero
                        disabled={isEditing === false || startingModalOption === 1}
                    />
                    {clientTypeError !== '' && <p style={{ color: 'red', fontWeight: 'bold' }}>{clientTypeError}</p>}
                </Col>
            </Row>

            {(clientType === 'PF' && jwt.groups.includes('ClientFizic') && startingModalOption === 1) &&
                <Row className="mt-4">
                    <DateDeIdentificarePF isEditing={isEditing} innerRef={dateIdentifPFRef} formData={formData} handleFormData={handleFormData} isEmailDisabled={true} />
                </Row>
            }

            {(clientType === 'PF' && jwt.groups.includes('ClientFizic') && startingModalOption === 2) &&
                <Row className="mt-4">
                    <DateDeIdentificarePFOptEmail isEditing={isEditing} innerRef={dateIdentifPFRef} formData={formData} handleFormData={handleFormData} />
                </Row>
            }

            {(clientType === 'PJ' && jwt.groups.includes('ClientFizic') && startingModalOption === 1) &&
                <Row className="mt-4">
                    <DateDeIdentificarePJ isEditing={isEditing} innerRef={dateIdentifPJRef} formData={formData} handleFormData={handleFormData} isEmailDisabled={false} />
                </Row>
            }

            {(clientType === 'PJ' && jwt.groups.includes('ClientFizic') && startingModalOption === 2) &&
                <Row className="mt-4">
                    <DateDeIdentificarePJOptEmail isEditing={isEditing} innerRef={dateIdentifPJRef} formData={formData} handleFormData={handleFormData} />
                </Row>
            }

            {(clientType === 'PF' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1) &&
                <Row className="mt-4">
                    <DateDeIdentificarePF isEditing={isEditing} innerRef={dateIdentifPFRef} formData={formData} handleFormData={handleFormData} isEmailDisabled={false} />
                </Row>
            }

            {(clientType === 'PF' && jwt.groups.includes('ClientJuridic') && startingModalOption === 2) &&
                <Row className="mt-4">
                    <DateDeIdentificarePFOptEmail isEditing={isEditing} innerRef={dateIdentifPFRef} formData={formData} handleFormData={handleFormData} />
                </Row>
            }

            {(clientType === 'PJ' && jwt.groups.includes('ClientJuridic') && startingModalOption === 1) &&
                <Row className="mt-4">
                    <DateDeIdentificarePJ isEditing={isEditing} innerRef={dateIdentifPJRef} formData={formData} handleFormData={handleFormData} isEmailDisabled={true} />
                </Row>
            }

            {(clientType === 'PJ' && jwt.groups.includes('ClientJuridic') && startingModalOption === 2) &&
                <Row className="mt-4">
                    <DateDeIdentificarePJOptEmail isEditing={isEditing} innerRef={dateIdentifPJRef} formData={formData} handleFormData={handleFormData} />
                </Row>
            }

            {clientType !== '' &&
                <Row>
                    <ChoiceComponent
                        title={'Sunt client:*'}
                        options={[{ title: 'Nou', id: true }, { title: 'Existent', id: false }]}
                        onChange={(option: number) => {
                            setNewClientError('');
                            handleNewClient(option);
                        }}
                        value={newClient}
                        disabled={isEditing === false}
                        id="client"
                    />
                </Row>
            }
            {newClientError !== '' && <p style={{ color: 'red', fontWeight: 'bold' }}>{newClientError}</p>}

            {(clientType !== '' && clientTypes.length > 0) &&
                <Row>
                    <Col xs={12} sm={6}>
                        <Select
                            onChange={(e: any) => {
                                setClientTypesErr('');
                                handleType(e.target.value);
                            }}
                            options={clientTypes}
                            placeholder={'Tip client*'}
                            value={type}
                            disabled={isEditing === false}
                            id="tip"
                        />
                    </Col>
                </Row>
            }
            {clientTypesErr !== '' && <p style={{ color: 'red', fontWeight: 'bold' }}>{clientTypesErr}</p>}

            {clientType !== '' &&
                <Row>
                    <Col>
                        <ChoiceComponent
                            title={'Fac parte dintr-un grup de persoane:*'}
                            options={[{ title: 'Da', id: true }, { title: 'Nu', id: false }]}
                            onChange={(option: number) => {
                                setGroupError('');
                                handleGroup(option);
                            }}
                            value={group}
                            disabled={isEditing === false}
                            id="grup"
                        >
                            <Tippy
                                content={
                                    <div className="p-3" style={{ background: 'rgba(0,0,0,0.9)', color: 'white', borderRadius: '6px' }}>
                                        De regulă, în cazul extinderilor de conductă, împreună cu alți solicitanți.
                                    </div>
                                }
                                duration={0}
                                placement="top"
                            >
                                <div className="icon-div" style={{ marginLeft: '8px' }}>
                                    <FontAwesomeIcon icon={faCircleInfo} className="icon" color="red" />
                                </div>
                            </Tippy>
                        </ChoiceComponent>
                    </Col>
                </Row>
            }
            {groupError !== '' && <p style={{ color: 'red', fontWeight: 'bold' }}>{groupError}</p>}

            {clientType !== '' &&
                <Row>
                    <ChoiceComponent
                        title={'Declar că:*'}
                        options={[{ title: 'Sunt dezvoltator imobiliar', id: true }, {
                            title: 'Nu sunt dezvoltator imobiliar',
                            id: false
                        }]}
                        onChange={(option: number) => {
                            setDezvoltatorError('');
                            handleDezvoltator(option);
                        }}
                        value={dezvoltator}
                        disabled={isEditing === false}
                        id="dev"
                    />
                </Row>
            }
            {dezvoltatorError !== '' && <p style={{ color: 'red', fontWeight: 'bold' }}>{dezvoltatorError}</p>}

            {(clientType !== null && clientType !== '' && startingModalOption === 1) &&
                <ChoiceComponent
                    title={'Adresa de corespondență este:*'}
                    options={[{ title: 'Adresa clientului final', id: 'AU' }, { title: 'Altă adresă', id: 'AA' }]}
                    onChange={(option: number) => {
                        setAdressTypeError('');
                        handleAdressType(option);
                    }}
                    value={adressType}
                    disabled={isEditing === false}
                    id="ac"
                />
            }

            {(adressTypeError !== '' && startingModalOption === 1) && <p style={{ color: 'red', fontWeight: 'bold' }}>{adressTypeError}</p>}

            {(clientType !== null && clientType !== '' && adressType === 'AA' && startingModalOption === 1) &&
                <Adress isEditing={isEditing} innerRef={adressRef} formData={adressData} handleFormData={handleAdressData} />
            }

            {(error !== '') && <p style={{ color: 'red', fontWeight: 'bold' }}>{error}</p>}
            <div className="d-flex flex-row justify-content-between mt-3 mb-3">
                <Button className="d-flex" disabled>Pasul anterior</Button>
                <Button className="d-flex" onClick={() => handleNextStep()}>Pasul următor</Button>
            </div>

            {renderModal()}
        </div>
    );
};

export default ClientFinal;