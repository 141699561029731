import moment from 'moment';

export const downloadBlobWithFilename = (
  blobPart: BlobPart,
  fileName: string,
  extension: string,
  withTimestamp: boolean,
  onSucces: () => any,
  onError: (error: any) => any
) => {
  try {
    setTimeout(() => {
      const blob: Blob = new Blob([blobPart]);

      const finalFileName: string = `${fileName}${withTimestamp ? '-' : ''}${
        withTimestamp ? moment().format('YYMMDDHms') : ''
      }${extension}`;
      const objectUrl: string = URL.createObjectURL(blob);
      const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

      a.href = objectUrl;
      a.download = finalFileName;
      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(objectUrl);
      onSucces();
    }, 500);
  } catch (error) {
    onError(error);
  }
};
